export const API_ROUTES = {
  CREATE_ACCESS_TOKEN: 'account/create_access_token',
  SEND_EMAIL_VERIFICATION: 'account/send_email_verification',
  SIGN_UP: 'account/register',
  CHECK_EMAIL_VERIFICATION: 'account/check_email_verification',
  RE_CONFIRM_EMAIL_VERIFICATION: 'account/check_verification_and_send_email',
  SEND_PASSWORD_RECOVERY: 'account/send_password_recovery',
  CHECK_PASSWORD_RECOVERY: 'account/check_password_recovery',
  CREATE_PROFILE: 'profile/full',
  PROPERTY: '/property',
  NOTIFICATION: '/notification',
  GET_ALL_PROFILE_INFO: 'profile/full',
  GET_CONTACT_INFO: 'profile/contact',
  OFFER: 'offer',
  INTERACTION_ACCEPT_OFFER: 'interaction/accept_offer',
  PAYMENTS: 'payment',
  ACCOUNT: 'account',
  PROFILE: 'profile',
};

export const APP_ROUTES = {
  LOGIN: '/login',
  ACCESS_RECOVERY: '/access-recovery',
  EMAIL_VERIFY: '/email-verify',
  FORGOT_PASSWORD: '/forgot-password',
  SIGN_UP: '/sign-up',
  MAIN: '/',
  BROKER_FIRST_LOGIN: '/first-login',
  PROFILE: '/profile',
  CREATE_PROPERTY: '/create-property',
  OFFERS: '/offers',
  PROPERTY: '/offers-property',
  WON_DEALS: '/won-deals',
  NOTIFICATIONS: '/notifications',
  LOCATION: '/location',
  SEND_EMAIL_VERIFICATION: '/send-email-verification',
};

export const TOAST_MESSAGES = {
  ERROR_ROLE: 'Please select a role',
  ERROR_POLICY: 'Please check the checkbox for the rules and privacy policy',
  SUCCESS: 'Successfully!',
  SUCCESS_EMAIL: 'You have successfully verified your email. You can now log in',
  SUCCESS_RE_SEND_EMAIL: 'The email was sent',
  PASSWORD_CHANGE_SUCCESS: 'Password successfully updated',
  DELETE_SUCCESS: 'Delete successfully!',
  ERROR_SIGN_UP: 'Sign up error',
  ERROR_LOGIN: 'Log in error. Please check your email and password',
  ERROR_EMAIL: 'Error not found',
  ERROR_VERIFICATION: 'Email verification error',
  ERROR_CREATE_COMPANY: 'Create company error',
  ERROR_CREATE_PROPERTY: 'Create property error',
  ERROR_DELETE_PROPERTY: 'Delete property error',
  ERROR_ADDRESS: 'Address not found',
  ERROR_ACCEPT_OFFER: 'Accept offer error',
  ERROR_RETRACT_OFFER: 'Error retract offer',
  ERROR_CREATE_OFFER: 'Error create offer',
  ERROR_GET_PROPERTIES: 'Get properties error',
  ERROR_SET_READ_NOTIFICATION: 'Error set read status for notification',
  ERROR_PAYMENT: 'Error payment',
  ERROR_UPLOAD_IMAGE: 'Error upload avatar',
  ERROR_DELETE_IMAGE: 'Error delete avatar',
};

export const statusesOffers = [
  { label: 'Accepted', value: 'accepted', visible: false },
  { label: 'Published', value: 'published', visible: false },
  { label: 'Contacted Owner', value: 'contacted_owner', visible: true },
  { label: 'Signed Contract', value: 'signed_contract', visible: true },
  { label: 'Property Sold', value: 'property_sold', visible: true },
];

export const callbackVerifyURL = `${process.env.REACT_APP_BASE_URL}/email-verify`;
export const callbackAccessRecoveryURL = `${process.env.REACT_APP_BASE_URL}/forgot-password`;

export const rulesURL = 'https://fetchabroker.com/rules/';
export const termsURL = 'https://fetchabroker.com/terms/';
export const privacyURL = 'https://fetchabroker.com/privacy/';
export const supportURL = 'https://fetchabroker.com/support/';

export const regexPhonePattern = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
export const fontFamily = 'Public Sans, sans-serif';
