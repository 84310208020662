import styled from 'styled-components';
import { Button } from '@mui/material';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  margin-top: 20vh;
`;

export const MessageBlock = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 10%;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    min-width: 250px;
  }
`;
