import React, { memo, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  LogoIcon,
  HeaderContainer,
  BurgerMenuIcon,
  CloseMenuIcon,
  MainHeaderContainer,
  OpenMenuContainer,
  MenuItemsContainer,
  MenuItem as MenuItemLink,
  LogoutIcon,
  HrLine,
  MenuItemLogout,
  NotificationsIconStyled,
  KeyboardArrowDownIconStyled,
  ProfileBlock,
  ProfileName,
  ProfileMenu,
  MenuStyled,
  MenuForWeb,
  MenuForWebItem,
  LogoContainer,
  LinkStyled,
  LogoutContainer,
} from './styles';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import { Badge } from '@mui/material';
import { brokerMenuItems, defaultMenuItems } from './constants';

import { getCountNotifications } from '../../slices/notifications';

export const Header = memo(props => {
  const dispatch = useDispatch();
  const { count } = useSelector(state => state.notifications);
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCountNotifications());
    const intervalId = setInterval(() => {
      dispatch(getCountNotifications());
    }, Number(process.env.REACT_APP_BASE_TIME_NOTIFICATION));

    return () => clearInterval(intervalId);
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuItems = () => {
    if (props.currentUser?.role.name === 'Broker') {
      return [...defaultMenuItems, ...brokerMenuItems];
    }
    return defaultMenuItems;
  };

  return (
    <MainHeaderContainer>
      <HeaderContainer>
        {props.currentUser?.role.name === 'Homeowner' && (
          <Link to={APP_ROUTES.MAIN}>
            <LogoIcon />
          </Link>
        )}
        {props.currentUser?.role.name === 'Broker' && (
          <LogoContainer>
            <Link to={APP_ROUTES.MAIN}>
              <LogoIcon />
            </Link>
            <MenuForWeb>
              {brokerMenuItems.map(item => (
                <MenuForWebItem key={item.title} to={item.link}>
                  {item.title}
                </MenuForWebItem>
              ))}
            </MenuForWeb>
          </LogoContainer>
        )}
        {!showMenu ? (
          <BurgerMenuIcon onClick={() => setShowMenu(!showMenu)} />
        ) : (
          <CloseMenuIcon onClick={() => setShowMenu(!showMenu)} />
        )}

        <ProfileBlock>
          <Badge badgeContent={count?.count_unread_messages ? 1 : 0} color="error">
            <LinkStyled to={APP_ROUTES.NOTIFICATIONS}>
              <NotificationsIconStyled />
            </LinkStyled>
          </Badge>
          <ProfileMenu
            onClick={e => {
              setShowProfileMenu(!showProfileMenu);
              handleClick(e);
            }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <KeyboardArrowDownIconStyled />
            <ProfileName>{props.currentUser.first_name}</ProfileName>
          </ProfileMenu>
        </ProfileBlock>
        <MenuStyled
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          // MenuListProps={{
          //   'aria-labelledby': 'basic-button',
          // }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(APP_ROUTES.PROFILE);
            }}>
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              props.logout();
            }}>
            Logout
          </MenuItem>
        </MenuStyled>
      </HeaderContainer>
      {showMenu && (
        <OpenMenuContainer>
          <MenuItemsContainer>
            {getMenuItems().map(item => (
              <MenuItemLink key={item.title} to={item.link} onClick={() => setShowMenu(!showMenu)}>
                {item.title}
              </MenuItemLink>
            ))}
          </MenuItemsContainer>

          <MenuItemLogout>
            <HrLine />
            <LogoutContainer
              onClick={() => {
                setShowMenu(!showMenu);
                props.logout();
              }}>
              <LogoutIcon />
              Log out
            </LogoutContainer>
          </MenuItemLogout>
        </OpenMenuContainer>
      )}
    </MainHeaderContainer>
  );
});
