import { Button } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as To } from '../../icons/to.svg';
import { ReactComponent as Delete } from '../../icons/delete.svg';

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  margin-top: 24px;
  box-shadow: ${props => props.theme.shadow.cardShadow};
`;

export const ButtonsGroup = styled.div`
  display: block;
  width: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    width: 400px;
    justify-content: flex-end;
  }
`;

export const PropertyInfoBlock = styled.div`
  padding: 16px;
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    height: 286px;
    justify-content: space-between;
    padding: 32px 24px;
  }
`;

export const PropertyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Address = styled.h2`
  font: ${props => props.theme.typography.h2_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font: ${props => props.theme.typography.h2.font};
  }
`;

export const Status = styled.div`
  padding: 8px 14px;
  border-radius: 100px;
  font-weight: 500;
  line-height: 22.4px;
  height: fit-content;
  text-transform: capitalize;

  &.green {
    color: #1b5e20;
    background-color: #b0dcb2;
  }

  &.red {
    color: #c62828;
    background-color: #f8b4b2;
  }

  &.yellow {
    color: #c29c2c;
    background-color: #f8f58b;
  }
`;

export const ContainerAddress = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: space-between;
  }
`;

export const Price = styled.h2`
  font: ${props => props.theme.typography.h3_mobile.font};
  margin-top: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font: ${props => props.theme.typography.h3.font};
  }
`;

export const AdditionalInfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }

  &.property-info {
    display: flex !important;
    margin-top: 32px;
    gap: 15px;
  }
`;

export const CreateButton = styled(Button)`
  width: 100%;
  &.MuiButton-root {
    text-transform: none;
    box-shadow: ${props => props.theme.shadow.buttonShadow};
    height: 56px;
    margin-top: 24px;
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    &.MuiButton-root {
      max-width: 180px;
      margin-top: 0;
      margin-right: 16px;
    }
  }
`;

export const DeleteButton = styled(Button)`
  width: 100%;
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    margin-top: 16px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 12px;
    box-shadow: ${props => props.theme.shadow.buttonShadow};
    border: 1px solid ${props => props.theme.palette.secondary};
    border-color: ${props => props.theme.palette.secondary};
    color: ${props => props.theme.palette.secondary};
    font: ${props => props.theme.typography.button_secondary.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.simpleGray};
    border: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    &.MuiButton-root {
      margin-top: 0;
    }
  }
`;

export const ToIcon = styled(To)`
  margin-left: 12px;
`;

export const DeleteIconBlack = styled(Delete)`
  margin-left: 12px;
  fill: red;
`;

export const RedText = styled.span`
  color: #b41117;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
`;

export const Text = styled.div`
  font-size: 26px;
  font-weight: 500;
  line-height: 31.2px;
`;

export const PropertyAdditionalInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  color: #76797d;
  font-size: 16px;
  letter-spacing: 0.15px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 14px;
  }
`;

export const Ellipse = styled.div`
  width: 8px;
  height: 8px;
  background-color: #76797d;
  border-radius: 100px;
`;
