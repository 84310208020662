import React, { useEffect, useMemo, useState } from 'react';
import {
  AdditionalText,
  AvailableServicesText,
  BrokerContainer,
  BrokerImage,
  BrokerInfo,
  BrokerLocation,
  BrokerName,
  BrokerNotFoundImage,
  DoneAllIcon,
  Ellipse,
  OfferInfo,
  OfferText,
  PropertyAdditionalText,
  PropertyContactInfo,
  PropertyContainer,
  PropertyCreatedAt,
  PropertyInfo,
  PropertyPrice,
  RedButton,
  RedText,
  ServiceCard,
  ServicesContainer,
  TitleValue,
  YourOffer,
  YourOfferContainer,
} from './styles';
import { format } from 'date-fns';
import ModalRetractOffer from '../../components/property-offers/modal/retract-offer';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOffer } from '../../slices/offer';
import { formatNumber } from '../../utils/helpers';
import ProfileService from '../../services/profile.service';

const PreviewOffer = ({ offer, property, isOwner }) => {
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (avatar) setAvatar(null);

    if (offer?.broker?.id) {
      ProfileService.getAvatarById(offer.broker.id).then(({ data }) => setAvatar(data));
    }
  }, [offer]);

  const readerImage = () => {
    return URL.createObjectURL(avatar);
  };

  const offerMemo = useMemo(() => {
    const { profile } = offer?.broker || {};
    const services = profile?.services?.provides?.map(({ name }) => name) || [];
    return {
      ...offer,
      isAccepted: Boolean(offer?.accepted_at),
      company: { name: profile?.company?.name, web_site: profile?.company?.web_site },
      about: { experience: profile?.about?.experience, bio: profile?.about?.bio },
      services: services,
    };
  }, [offer]);

  const retractOffer = () => {
    dispatch(deleteOffer(offer.id));
    setOpen(false);
  };

  const contactInfo = () => {
    if (property.offers?.length && offerMemo.isAccepted && offer.broker.id === user.id) {
      return `${property.contact_first_name} ${property.contact_last_name}`;
    }
    return property.owner.first_name;
  };

  const propertyAdditionalInfo = () => {
    return {
      Bathrooms: property.bathrooms || 0,
      Bedrooms: property.bedrooms || 0,
      sqft: property.sqrt || 0,
      'Built in': property.built_in || 0,
    };
  };

  const commissionType = offerMemo?.commission_type === 'flat' ? '$' : '%';

  return (
    <>
      {!isOwner && (
        <PropertyContainer>
          <div>
            <PropertyPrice>{`Desired Price: \n${formatNumber(property.cost)}`}</PropertyPrice>
            {Object.entries(propertyAdditionalInfo()).map(([key, value]) => (
              <PropertyAdditionalText key={key}>
                <Ellipse />
                <div>{value}</div>
                <div>{key}</div>
              </PropertyAdditionalText>
            ))}
          </div>
          <PropertyInfo>
            <PropertyContactInfo>
              <div>
                <TitleValue>Homeowner:</TitleValue> {contactInfo()}
              </div>
              {offer && offerMemo.isAccepted && offer.broker.id === user.id && (
                <>
                  <div>
                    <TitleValue>Phone:</TitleValue> {property.contact_phone}
                  </div>
                  <div>
                    <TitleValue>Email:</TitleValue> {property.contact_email}
                  </div>
                </>
              )}
            </PropertyContactInfo>
            <PropertyCreatedAt>Published: {format(new Date(property.created_at), 'MM.dd.yyyy')}</PropertyCreatedAt>
          </PropertyInfo>
        </PropertyContainer>
      )}

      {offer && (
        <>
          <YourOffer>{offerMemo.isAccepted ? 'Accepted offer' : 'Your offer'}</YourOffer>
          <YourOfferContainer>
            <BrokerContainer>
              <BrokerInfo>
                {avatar ? <BrokerImage src={readerImage()} /> : <BrokerNotFoundImage />}
                <div>
                  <BrokerName>Broker: {`${offer.broker.first_name} ${offer.broker.last_name}`}</BrokerName>
                  <BrokerLocation>{`${offer.broker.profile.company.city} ${offer.broker.profile.company.state}`}</BrokerLocation>
                </div>
                {(!offerMemo.isAccepted || !offer) && (
                  <RedButton className="desktop" onClick={() => setOpen(true)} variant="contained" color="error">
                    Retract
                  </RedButton>
                )}
              </BrokerInfo>

              <OfferInfo>
                <AvailableServicesText>Offer Details</AvailableServicesText>
                <p>
                  Terms: <RedText>{offer.terms} Months</RedText>
                </p>
                <p>
                  Commissions: <RedText>{` ${offerMemo.total_commission} ${commissionType}`}</RedText> (Buyer:
                  <RedText>{` ${offerMemo.buyer_commission} ${commissionType}`}</RedText> + Seller:
                  <RedText>{` ${offerMemo.seller_commission} ${commissionType}`}</RedText>)
                  <AdditionalText>
                    **Commissions may be subject to negotiations involving the seller agent
                  </AdditionalText>
                </p>
              </OfferInfo>

              <OfferText>{offerMemo.about.bio}</OfferText>

              <div>
                <AvailableServicesText>Available Services</AvailableServicesText>
                <AdditionalText>**Additional fees may apply</AdditionalText>
              </div>

              {Boolean(offerMemo.services.length) && (
                <ServicesContainer>
                  {offerMemo.services.map(service => (
                    <ServiceCard key={service}>
                      <DoneAllIcon />
                      {service}
                    </ServiceCard>
                  ))}
                </ServicesContainer>
              )}
            </BrokerContainer>

            {(!offerMemo.isAccepted || !offer) && (
              <RedButton className="mobile" fullWidth onClick={() => setOpen(true)} variant="contained" color="error">
                Retract
              </RedButton>
            )}
          </YourOfferContainer>

          <ModalRetractOffer open={open} closeFun={() => setOpen(false)} submit={retractOffer} />
        </>
      )}
    </>
  );
};

export default PreviewOffer;
