import { APP_ROUTES } from '../../utils/constants';

export const defaultMenuItems = [
  { title: 'Profile', link: APP_ROUTES.PROFILE },
  { title: 'Notifications', link: APP_ROUTES.NOTIFICATIONS },
];

export const brokerMenuItems = [
  { title: 'Properties', link: APP_ROUTES.MAIN },
  { title: 'Open offers', link: APP_ROUTES.OFFERS },
  { title: 'Won deals', link: APP_ROUTES.WON_DEALS },
  { title: 'Target location', link: APP_ROUTES.LOCATION },
];
