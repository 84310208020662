import styled from 'styled-components';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import { ReactComponent as MenuI } from '../../icons/menu.svg';
import { ReactComponent as Logout } from '../../icons/logout.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Menu from '@mui/material/Menu';

export const MainHeaderContainer = styled.div``;

export const LogoIcon = styled(Logo)`
  width: 80px;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 132px;
  }
`;

export const BurgerMenuIcon = styled(MenuI)`
  display: block;
  width: 24px;
  height: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 132px;
    display: none;
  }
`;

export const CloseMenuIcon = styled(Close)`
  width: 24px;
  height: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 24px;
  box-shadow: ${({ theme }) => theme.shadow.headerShadow};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 97px;
  }
`;

export const LogoutButton = styled.button`
  display: none;
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  padding-top: 38px;
  margin-left: 24px;
`;

export const MenuItem = styled(Link)`
  font: ${({ theme }) => theme.typography.headerMenu};
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
`;

export const MenuItemLogout = styled(Link)`
  position: absolute;
  width: 100%;
  bottom: 40px;
  font: ${({ theme }) => theme.typography.headerMenu};
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
  padding-left: 24px;
  padding-right: 24px;
`;

export const OpenMenuContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const HrLine = styled.hr`
  border: 0.5px solid ${({ theme }) => theme.palette.lightGray};
  margin-bottom: 26px;
`;

export const LogoutIcon = styled(Logout)`
  width: 24px;
`;

export const NotificationsIconStyled = styled(NotificationsIcon)`
  width: 24px;
  cursor: pointer;
`;

export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)`
  width: 24px;
`;

export const KeyboardArrowUpIconStyled = styled(KeyboardArrowUpIcon)`
  width: 24px;
`;

export const ProfileBlock = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
`;

export const ProfileName = styled.span`
  margin-left: 6px;
`;

export const ProfileMenu = styled.button`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    margin-left: 40px;
    background: ${({ theme }) => theme.palette.white};
    font: ${({ theme }) => theme.typography.txt};
  }
`;

export const MenuStyled = styled(Menu)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

export const MenuForWeb = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    gap: 56px;
    margin-left: 108px;
  }
`;

export const MenuForWebItem = styled(Link)`
  text-decoration: none;
  font: ${({ theme }) => theme.typography.headerMenu};
  color: ${({ theme }) => theme.palette.black};
`;

export const LogoContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
`;

export const LogoutContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
