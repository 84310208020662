import styled from 'styled-components';
import { ReactComponent as Badge } from '../../icons/badge.svg';

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  margin-top: 24px;
  box-shadow: ${props => props.theme.shadow.cardShadow};
  min-height: 150px;
`;

export const ContentContainer = styled.div`
  padding: 16px 12px 16px 12px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 32px 32px 32px 32px;
  }
`;

export const CardTitle = styled.h3`
  font: ${props => props.theme.typography.h3.font};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardText = styled.p`
  margin-top: 24px;
  font: ${props => props.theme.typography.txt.font};
  color: ${props => props.theme.palette.simpleGray};
`;

export const CardDateWeb = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${props => props.theme.typography.txt.font};
    color: ${props => props.theme.palette.simpleGray};
    display: block;
  }
`;

export const CardDateMobile = styled.span`
  font: ${props => props.theme.typography.txt.font};
  color: ${props => props.theme.palette.simpleGray};
  display: block;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const BadgeIcon = styled(Badge)`
  margin-right: 8px;
`;
