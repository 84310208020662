import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from '../../slices/profile';

const PrivateRoutesWithSubscription = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector(state => state.auth);
  const isFirstLogin = location.pathname === APP_ROUTES.BROKER_FIRST_LOGIN;

  if (user.role.code !== 'broker') return <Outlet />;
  if (!user.is_subscribed_broker && !isFirstLogin) {
    dispatch(setStep(3));
    return <Navigate to={APP_ROUTES.BROKER_FIRST_LOGIN} />;
  }
  return <Outlet />;
};

export default PrivateRoutesWithSubscription;
