import { API_ROUTES } from '../utils/constants';
import axios from '../utils/axios/index';

const getMetaData = () => {
  return axios.get(API_ROUTES.ACCOUNT + '/meta');
};

const createMetaData = data => {
  const distance = data.distance.toFixed(2);
  const query = `?latitude=${data.latitude}&longitude=${data.longitude}&distance=${distance}&srid=${data.srid}`;
  return axios.post(API_ROUTES.ACCOUNT + '/meta' + query);
};

const getAvatar = () => {
  return axios.get(API_ROUTES.ACCOUNT + '/avatar', {
    responseType: 'blob',
  });
};

const uploadAvatar = file => {
  const formData = new FormData();
  formData.append('avatar', file);
  return axios.post(API_ROUTES.ACCOUNT + '/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteAvatar = () => {
  return axios.delete(API_ROUTES.ACCOUNT + '/avatar');
};

const AccountService = {
  getMetaData,
  createMetaData,
  uploadAvatar,
  getAvatar,
  deleteAvatar,
};

export default AccountService;
