import React, { useEffect, useState } from 'react';
import {
  BrokerFIO,
  ButtonsContainer,
  CloseIcon,
  CustomButton,
  CustomModal,
  HeadContainer,
  MainContainer,
  OfferInfo,
  BrokerBio,
  RedText,
  CheckBoxLabel,
  CustomCheckBox,
  ServicesContainer,
  DoneAllIcon,
  ServiceCard,
  AvailableContainer,
  AvailableServicesText,
  AdditionalText,
  BrokerImage,
  BrokerInfo,
  BrokerNotFoundImage,
  BrokerCity,
  OfferDetailsTitle,
  CommissionsDetails,
  OfferDetailsText,
  CloseButton,
} from './styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import ProfileService from '../../../../services/profile.service';

const ModalAcceptOffer = ({ offer, closeFun, submit }) => {
  const [avatar, setAvatar] = useState(null);
  const [acceptRules, setAcceptRules] = useState(false);
  const { broker, terms, commission_type } = offer || {};
  const services = broker?.profile?.services?.provides?.map(({ name }) => name) || [];
  const commissionType = commission_type === 'flat' ? '$' : '%';

  useEffect(() => {
    if (avatar) setAvatar(null);

    if (broker?.id) {
      ProfileService.getAvatarById(broker.id).then(({ data }) => setAvatar(data));
    }
  }, [offer]);

  const readerImage = () => {
    return URL.createObjectURL(avatar);
  };

  return (
    <CustomModal
      open={Boolean(offer)}
      onClose={closeFun}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <MainContainer>
        <HeadContainer>
          {broker && (
            <BrokerInfo>
              {avatar ? <BrokerImage src={readerImage()} /> : <BrokerNotFoundImage />}
              <div>
                <BrokerFIO>Broker: {`${broker.first_name} ${broker.last_name[0]}***`}</BrokerFIO>
                <BrokerCity>{`${broker?.profile?.company?.city}, ${broker?.profile?.company?.state}`}</BrokerCity>
              </div>
            </BrokerInfo>
          )}
          <CloseButton onClick={closeFun}>
            <CloseIcon />
          </CloseButton>
        </HeadContainer>
        <OfferInfo>
          <OfferDetailsTitle>Offer Details</OfferDetailsTitle>
          <OfferDetailsText>
            Terms: <RedText>{terms} Months</RedText>
          </OfferDetailsText>
          <OfferDetailsText>
            Commission:
            <RedText>{` ${offer?.total_commission} ${commissionType}`}</RedText>
            <CommissionsDetails>
              (Buyer:
              <RedText>{` ${offer?.buyer_commission} ${commissionType}`}</RedText> + Seller:
              <RedText>{` ${offer?.seller_commission} ${commissionType}`}</RedText>)
            </CommissionsDetails>
            <AdditionalText>**Commissions may be subject to negotiations involving the seller agent</AdditionalText>
          </OfferDetailsText>
        </OfferInfo>
        <BrokerBio>{broker?.profile?.about?.bio}</BrokerBio>
        <AvailableContainer>
          <AvailableServicesText>Available Services</AvailableServicesText>
          <AdditionalText> **Additional fees may apply</AdditionalText>
        </AvailableContainer>
        {Boolean(services.length) && (
          <ServicesContainer>
            {services.map(service => (
              <ServiceCard key={service}>
                <DoneAllIcon />
                {service}
              </ServiceCard>
            ))}
          </ServicesContainer>
        )}
        <FormControlLabel
          control={
            <CustomCheckBox
              size="small"
              color="error"
              onChange={() => setAcceptRules(!acceptRules)}
              checked={acceptRules}
            />
          }
          label={
            <CheckBoxLabel>
              {'If you click the "Accept" button, you agree to list your property with this broker and to our '}
              <a style={{ color: '#131217' }} href={'https://fetchabroker.com/rules/'}>
                rules.
              </a>
            </CheckBoxLabel>
          }
        />
        <ButtonsContainer>
          <CustomButton onClick={closeFun} variant="outlined">
            Reject
          </CustomButton>
          <CustomButton disabled={!acceptRules} onClick={submit} variant="contained" color="error">
            Accept Offer
          </CustomButton>
        </ButtonsContainer>
      </MainContainer>
    </CustomModal>
  );
};

export default ModalAcceptOffer;
