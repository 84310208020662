import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';
import AccountService from '../services/account.service';

export const createMeta = createAsyncThunk('account/create-meta', async (data, thunkAPI) => {
  try {
    const response = await AccountService.createMetaData(data);

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const getMetaDataAccount = createAsyncThunk('account/meta', async (data, thunkAPI) => {
  try {
    const response = await AccountService.getMetaData();

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const uploadAvatar = createAsyncThunk('profile/upload-avatar', async (data, thunkAPI) => {
  try {
    const response = await AccountService.uploadAvatar(data);

    return response.data;
  } catch (error) {
    const status = error?.response?.status;
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(status === 400 ? message : null);
  }
});

export const deleteAvatar = createAsyncThunk('profile/del-avatar', async (data, thunkAPI) => {
  try {
    const response = await AccountService.deleteAvatar();

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
  loading: false,
  updateMeta: false,
  uploadImage: false,
  meta: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setMeta: (state, action) => {
      state.meta = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createMeta.fulfilled, (state, action) => {
        state.updateMeta = false;
      })
      .addCase(createMeta.pending, state => {
        state.updateMeta = true;
      })
      .addCase(createMeta.rejected, (state, action) => {
        state.updateMeta = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_CREATE_COMPANY);
      })

      .addCase(getMetaDataAccount.fulfilled, (state, action) => {
        state.meta = action.payload;
        state.loading = false;
      })
      .addCase(getMetaDataAccount.pending, state => {
        state.loading = true;
      })
      .addCase(getMetaDataAccount.rejected, state => {
        state.loading = false;
      })

      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.uploadImage = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(uploadAvatar.pending, state => {
        state.uploadImage = true;
      })
      .addCase(uploadAvatar.rejected, (state, action) => {
        state.uploadImage = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_UPLOAD_IMAGE);
      })

      .addCase(deleteAvatar.fulfilled, (state, action) => {
        state.uploadImage = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(deleteAvatar.pending, state => {
        state.uploadImage = true;
      })
      .addCase(deleteAvatar.rejected, state => {
        state.uploadImage = false;
        toast.error(TOAST_MESSAGES.ERROR_DELETE_IMAGE);
      });
  },
});

export const { setMeta } = accountSlice.actions;
export default accountSlice.reducer;
