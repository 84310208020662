import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  MainContainer,
  Container,
  Title,
  BackArrow,
  TitleContainer,
  ImageContainer,
  StyledImg,
  TextContainer,
  TextRed,
  Text,
  TextSmContainer,
  AddMenthodButton,
  PlusIcon,
  BtnContainer,
} from './styles';
import logo from '../../../icons/empty-payment.svg';
import { createSubscribe as createSubscribeApi } from '../../../slices/payment';

const AddPayment = ({ handleBack }) => {
  const dispatch = useDispatch();

  const createSubscribe = () => {
    dispatch(createSubscribeApi());
  };

  return (
    <MainContainer>
      <Container>
        <TitleContainer>
          <BackArrow onClick={handleBack} />
          <Title>Payment</Title>
        </TitleContainer>
        <ImageContainer>
          <StyledImg src={logo} />
          <BtnContainer>
            <TextContainer>
              <Text>To use the service, you need to add a payment method. </Text>
              <TextSmContainer>
                <Text>The cost is </Text>
                <TextRed>$9.99 per month.</TextRed>
              </TextSmContainer>
            </TextContainer>
            <AddMenthodButton onClick={createSubscribe}>
              <PlusIcon />
              Add payment method
            </AddMenthodButton>
          </BtnContainer>
        </ImageContainer>
      </Container>
    </MainContainer>
  );
};

export default memo(AddPayment);
