import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';
import ProfileService from '../services/profile.service';

const step = JSON.parse(localStorage.getItem('step')) ? JSON.parse(localStorage.getItem('step')) : 0;

export const createCompany = createAsyncThunk('profile/create-company', async (data, thunkAPI) => {
  try {
    const response = await ProfileService.createCompany(data);

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllProfileInfo = createAsyncThunk('profile/full', async (data, thunkAPI) => {
  try {
    const response = await ProfileService.getFullProfileInfo();

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getContactInfo = createAsyncThunk('profile/contact', async (data, thunkAPI) => {
  try {
    const response = await ProfileService.getContactInfo();

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
  loading: false,
  step,
  company: null,
  license: null,
  about: null,
  services: null,
  contact: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
      localStorage.setItem('step', JSON.stringify(action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createCompany.fulfilled, (state, action) => {
        const { company, license, about, services } = action.payload;
        state.company = company;
        state.license = license;
        state.about = about;
        state.services = services?.provides;
        state.loading = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(createCompany.pending, state => {
        state.loading = true;
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_CREATE_COMPANY);
      })
      .addCase(getAllProfileInfo.fulfilled, (state, action) => {
        const { company, license, about, services } = action.payload;
        state.company = company;
        state.license = license;
        state.about = about;
        state.services = services?.provides;
        state.loading = false;
      })
      .addCase(getAllProfileInfo.pending, state => {
        state.loading = true;
      })
      .addCase(getAllProfileInfo.rejected, state => {
        state.loading = false;
      })
      .addCase(getContactInfo.fulfilled, (state, action) => {
        state.contact = action.payload;
        state.loading = false;
      })
      .addCase(getContactInfo.pending, state => {
        state.loading = true;
      })
      .addCase(getContactInfo.rejected, state => {
        state.loading = false;
      });
  },
});
export const { setStep } = profileSlice.actions;
export default profileSlice.reducer;
