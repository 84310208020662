import styled from 'styled-components';
import { Box, Slider } from '@mui/material';

export const RedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: rgba(193, 32, 38, 1);
  }
  & .MuiSlider-thumb:hover {
    box-shadow: 0 0 0 10px rgba(193, 32, 38, 0.3) !important;
  }
  & .MuiSlider-thumb {
    box-shadow: 0 0 0 10px rgba(193, 32, 38, 0.1) !important;
  }
`;

export const BoxStyled = styled(Box)`
  width: 100%;
  margin-top: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 150px;
    margin-left: 20px;
    margin-top: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop_medium}) {
    width: 350px;
    margin-top: 0;
  }
`;
