import styled from 'styled-components';
import { Button, TableCell, TableContainer as Table, TableRow } from '@mui/material';

export const TableContainer = styled(Table)`
  &.MuiTableContainer-root {
    overflow-x: unset;
    border-radius: 8px;
    box-shadow:
      0px 0px 2px -2px rgba(41, 45, 79, 0.2),
      0px 0px 14px 0px rgba(0, 0, 0, 0.12);
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      display: none;
    }
  }
`;
export const CustomTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    &:nth-child(2n + 1) {
      background-color: #fcfcfc !important;
    }
  }
`;

export const RedButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 12px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }
`;

export const RedText = styled.span`
  color: #d32f2f;
`;

export const HeaderTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 27px 16px;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.4px;
    letter-spacing: 0.15px;
  }
`;

export const BodyTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.4px;
    letter-spacing: 0.15px;
  }
`;
