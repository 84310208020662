import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import { ReactComponent as Close } from '../../../../icons/close.svg';

export const MainContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 529px;
  width: calc(100% - 40px);
  background-color: white;
  border-radius: 12px;
  padding: 32px;
`;

export const CustomModal = styled(Modal)`
  background: rgba(30, 34, 42, 0.32);
  backdrop-filter: blur(2px);
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const HeadTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: 0.15px;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 24px;
  margin-top: 40px;
`;

export const CustomButton = styled(Button)`
  flex-grow: 1;
  &.MuiButton-root {
    min-width: 200px;
    padding: 9px 32px;
    border-radius: 12px;
    font-size: 20px;
    text-transform: capitalize;
    &:nth-child(1) {
      color: #2c2c2e;
      border: 1px solid #2c2c2e;
    }
  }
`;

export const Broker = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: 0.15px;
`;

export const CheckBoxLabel = styled.span`
  width: 100%;
`;
