import React from 'react';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  FormStyled,
  LogoIcon,
  FormLabel,
  FieldsContainer,
  HeadContainer,
  LoginButton,
  FormText,
  LinkStyledBack,
  BackIcon,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { schemaAccessRecovery } from '../../joi';
import { sendPasswordRecovery, setEmailForForgotPassword } from '../../slices/auth';
import { APP_ROUTES } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

export const AccessRecoveryPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemaAccessRecovery),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, sendEmailStatus, emailForForgot } = useSelector(state => state.auth);

  const onSubmit = data => {
    dispatch(sendPasswordRecovery(data.email));
    dispatch(setEmailForForgotPassword(data.email));
  };

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <LogoIcon />
      <HeadContainer>
        <FormLabel>
          {!sendEmailStatus ? (
            <>
              <LinkStyledBack to={APP_ROUTES.LOGIN}>
                <BackIcon />
              </LinkStyledBack>
              Access recovery
            </>
          ) : (
            'The login link is in your email'
          )}
        </FormLabel>
      </HeadContainer>
      <FieldsContainer>
        <FormText>
          {!sendEmailStatus ? 'A login link will be sent to your email' : `Check your email ${emailForForgot}`}
        </FormText>
        {!sendEmailStatus ? (
          <>
            <TextField
              name="email"
              label="Email"
              placeholder="Email"
              {...register('email')}
              inputProps={{ maxLength: 120 }}
              error={errors.email?.message ? true : false}
              helperText={errors.email?.message}
            />
            <LoginButton disabled={loading} type="submit" variant="contained">
              {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Send link'}
            </LoginButton>
          </>
        ) : (
          <LoginButton
            variant="contained"
            onClick={() => {
              navigate(APP_ROUTES.LOGIN);
            }}>
            Ok
          </LoginButton>
        )}
      </FieldsContainer>
    </FormStyled>
  );
};
