import React, { useEffect, useMemo } from 'react';
import { MainContainer } from './style';
import EmptyDeals from './emptyDeals';
import { useDispatch, useSelector } from 'react-redux';
import { getOffers } from '../../slices/offer';
import Loader from '../../components/loader';
import OffersList from '../../components/offers-list';
import { Title } from './style';

const BrokersWonDeals = () => {
  const dispatch = useDispatch();
  const { loading, offers } = useSelector(state => state.offers);
  const acceptedOffers = useMemo(() => {
    if (offers && offers.length > 0) return offers.filter(offer => !!offer.accepted_at);
    return [];
  }, [offers]);
  useEffect(() => {
    dispatch(getOffers());
  }, []);

  if (loading || !offers) return <Loader />;

  return (
    <MainContainer>
      {acceptedOffers.length === 0 ? (
        <EmptyDeals />
      ) : (
        <>
          <Title>Won deals</Title>
          <OffersList offers={acceptedOffers} />
        </>
      )}
    </MainContainer>
  );
};

export default BrokersWonDeals;
