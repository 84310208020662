import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as KeyboardBackSpace } from '../../../icons/keyboard-backspace.svg';
import { ReactComponent as PlusIconSvg } from '../../../icons/plus-icon.svg';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const MainContainer = styled.form`
  max-width: 1496px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 16px 24px;
  }
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  width: max-content;
  text-decoration: none;
  color: black;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 52.8px;
  letter-spacing: 0.15px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: 32px 64px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 24px 64px;
  }
`;

export const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.2px;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 24px;
    font-size: 24px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CustomTextField = styled(TextField)`
  min-width: 282px !important;
  //& :disabled {
  //  color: rgba(0, 0, 0, 0.87) !important;
  //  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  //}
`;

export const ServiceDetailsBlock = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  gap: 18px 39px;
`;

export const ServiceDetailsFields = styled.div`
  max-width: 653px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 30px;
    max-width: unset;
    width: 100%;
  }
`;

export const CustomCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    margin-left: 10px;
  }
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const CheckBoxLabel = styled.span`
  color: ${props => props.theme.palette.simpleGray};
  font: ${props => props.theme.typography.txt.font};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`;

export const SubTextDown = styled.span`
  margin-bottom: 6px;
  font: ${props => props.theme.typography.txt.font};
  color: #131217;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  letter-spacing: 0.15px;
`;

export const CheckboxsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// payment details
export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 339px;
  gap: 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const CustomButton = styled(Button)`
  flex-grow: 1;
  &.MuiButton-root {
    min-width: 200px;
    padding: 9px 32px;
    border-radius: 12px;
    font-size: 20px;
    text-transform: unset;
    color: ${props => props.theme.palette.secondary};
    border: 1px solid ${props => props.theme.palette.secondary};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 10px 20px;
    }
  }
`;

export const PlusIcon = styled(PlusIconSvg)``;

export const KeyboardBackSpaceIcon = styled(KeyboardBackSpace)`
  margin-right: 8px;
`;

export const RedButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 12px;
    text-transform: none;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 30px;
    margin-top: 15px;
  }
`;

export const AvatarImage = styled.img`
  border-radius: 120px;
  width: 191px;
  height: 191px;
  border: 1px solid grey;
  margin: 5px auto;
`;

export const AccountButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const AvatarIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 120px;
  width: 191px;
  height: 191px;
  border: 1px solid grey;
  margin: 5px auto;
  font-size: 56px;
`;

export const ImageInfo = styled.div`
  margin-top: 4px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid grey;
  padding: 10px;
  color: ${props => props.theme.palette.redMain};
  max-width: 300px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100%;
  }
`;
