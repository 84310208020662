export const defaultValues = {
  first_name: '-',
  last_name: '-',
  phone: '-',
  licenseState: '-',
  licenseNumber: '-',
  companyName: '-',
  companyStreet: '-',
  city: '-',
  state: '-',
  zipCode: '-',
  website: '-',
  experience: 0,
  bio: '-',
  sold: 0,
  socialMedia: false,
  mailers: false,
  openHouses: false,
  listingPhoto: false,
  listingVideo: false,
  drone: false,
  furniture: false,
};
