import { memo, useEffect } from 'react';
import { CardContainer, CardTitle, CardText, ContentContainer, CardDateWeb, BadgeIcon, CardDateMobile } from './styles';
import { useDispatch } from 'react-redux';
import { setReadStatusNotification } from '../../slices/notifications';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';

const NotificationCard = ({ item, user }) => {
  const dispatch = useDispatch();
  const isBroker = user.role.name === 'Broker';
  const formattedDate = format(new Date(item.created_at), 'MM.dd.yyyy HH:mm');

  useEffect(() => {
    if (!item.is_read) {
      dispatch(setReadStatusNotification(item.id));
    }
  }, []);

  const getText = () => {
    // for homeowner
    if (!isBroker) {
      return <Link to={APP_ROUTES.PROPERTY + `/${item.property_id}`}>Please click here to view the new offer.</Link>;
    }
    return '';
  };

  return (
    <CardContainer>
      <ContentContainer>
        <CardTitle>
          {!item.is_read ? (
            <h3>
              <BadgeIcon />
              {item.message}
            </h3>
          ) : (
            item.message
          )}
          <CardDateWeb>{formattedDate}</CardDateWeb>
        </CardTitle>
        <CardText>{getText()}</CardText>
        <CardDateMobile>{formattedDate}</CardDateMobile>
      </ContentContainer>
    </CardContainer>
  );
};

export default memo(NotificationCard);
