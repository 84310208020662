import React, { useEffect, useState } from 'react';
import ProfileService from '../../../../services/profile.service';
import {
  BrokerContainer,
  BrokerImage,
  BrokerInfoContainer,
  BrokerNotFoundImage,
  Container,
  Service,
  ServicesContainer,
} from './styles';
import { DoneAllIcon } from '../../../../pages/offers-property/styles';

const BrokerInfo = ({ broker }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    ProfileService.getAvatarById(broker.id).then(({ data }) => setAvatar(data));
  }, []);

  const readerImage = () => {
    return URL.createObjectURL(avatar);
  };

  const handleMouseEnter = e => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = e => {
    setDropdownVisible(false);
  };

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {`${broker.first_name} ${broker.last_name[0]}*****`}
      {isDropdownVisible && (
        <BrokerContainer>
          <BrokerInfoContainer>
            {avatar ? <BrokerImage src={readerImage()} alt={'image'} /> : <BrokerNotFoundImage />}
            <div>Broker: {broker.first_name} ***</div>
          </BrokerInfoContainer>
          <ServicesContainer>
            {broker.profile.services.provides.map(({ name }) => (
              <Service key={name}>
                <DoneAllIcon />
                {name}
              </Service>
            ))}
          </ServicesContainer>
        </BrokerContainer>
      )}
    </Container>
  );
};

export default BrokerInfo;
