import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import FormFirstLogin from './firstLoginForm';
import MapComponent from './mapComponent';
import AddPayment from './addPayment';
import { createCompany, getAllProfileInfo, setStep } from '../../slices/profile';
import { useDispatch } from 'react-redux';
import { getMetaDataAccount } from '../../slices/account';
import Loader from '../../components/loader';

const FirstLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { step, company, loading } = useSelector(state => state.profile);
  const { meta, loading: loadingMeta } = useSelector(state => state.account);

  const onSubmit = useCallback(
    data => {
      dispatch(createCompany(data));
      dispatch(setStep(2));
    },
    [setStep, createCompany]
  );

  const handleBack = useCallback(() => {
    dispatch(setStep(2));
  }, [setStep]);

  useEffect(() => {
    dispatch(getMetaDataAccount());
    dispatch(getAllProfileInfo());
  }, []);

  useEffect(() => {
    if (company !== null && company?.name !== null) {
      dispatch(setStep(2));
    } else {
      dispatch(setStep(1));
    }
  }, [company]);

  useEffect(() => {
    if (step === 2 && meta && Object.keys(meta).length) {
      if (user.is_subscribed_broker) {
        navigate(APP_ROUTES.MAIN);
      } else {
        dispatch(setStep(3));
      }
    }
    if (step === 3 && user.is_subscribed_broker) {
      navigate(APP_ROUTES.MAIN);
    }
  }, [step, user, meta]);

  if ((!company && loading) || (!meta && loadingMeta)) {
    return <Loader />;
  }

  switch (step) {
    case 1:
      return <FormFirstLogin onSubmit={onSubmit} loading={loading} />;
    case 2:
      return <MapComponent />;
    case 3:
      return <AddPayment handleBack={handleBack} />;

    default:
      return;
  }
};

export default FirstLoginPage;
