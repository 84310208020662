import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignUpPage from '../../pages/signUp';
import LoginPage from '../../pages/login';
import VerifyPage from '../../pages/verify';
import { BaseLayout } from '../../layouts/baseLayout';
import { APP_ROUTES } from '../../utils/constants';
import { MainPage } from '../../pages/main/index';
import FirstLoginPage from '../../pages/firstLogin';
import ProfilePage from '../../pages/profile';
import CreatePropertyComponent from '../../pages/createProperty';
import OffersProperty from '../../pages/offers-property';
import BrokersWonDeals from '../../pages/brokersWonDeals';
import BrokerOffers from '../../pages/brokerOffers';
import { NotificationsPage } from '../../pages/notifications';
import PrivateRoutesWithSubscription from '../../components/private-routes-with-subscription';
import { AccessRecoveryPage } from '../../pages/accessRecover';
import { ForgotPasswordPage } from '../../pages/forgotPassword';
import LocationPage from '../../pages/location';
import SendEmailVerificationPage from '../../pages/send-email-verification';

const Router = () => {
  const { isLoggedIn, emailConfirmed, user } = useSelector(state => state.auth);

  if (!isLoggedIn) {
    return (
      <BaseLayout>
        <Routes>
          <Route path={APP_ROUTES.SIGN_UP} element={<SignUpPage />} />
          <Route path={APP_ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={APP_ROUTES.ACCESS_RECOVERY} element={<AccessRecoveryPage />} />
          <Route path={APP_ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          <Route path={APP_ROUTES.EMAIL_VERIFY} element={<VerifyPage />} />
          <Route path={'*'} element={<Navigate to={APP_ROUTES.LOGIN} />} />
        </Routes>
      </BaseLayout>
    );
  }

  if (isLoggedIn && !emailConfirmed) {
    return (
      <BaseLayout>
        <Routes>
          <Route path={APP_ROUTES.EMAIL_VERIFY} element={<VerifyPage />} />
          <Route path={APP_ROUTES.SEND_EMAIL_VERIFICATION} element={<SendEmailVerificationPage />} />
          <Route path={'*'} element={<Navigate to={APP_ROUTES.SEND_EMAIL_VERIFICATION} />} />
        </Routes>
      </BaseLayout>
    );
  }

  const defaultUrl = user?.role?.code === 'broker' ? APP_ROUTES.BROKER_FIRST_LOGIN : APP_ROUTES.MAIN;

  return (
    <BaseLayout>
      <Routes>
        <Route path={APP_ROUTES.BROKER_FIRST_LOGIN} element={<FirstLoginPage />} />
        <Route element={<PrivateRoutesWithSubscription />}>
          <Route path={APP_ROUTES.MAIN} element={<MainPage />} />
          <Route path={APP_ROUTES.CREATE_PROPERTY} element={<CreatePropertyComponent />} />
          <Route path={APP_ROUTES.PROFILE} element={<ProfilePage />} />
          <Route path={APP_ROUTES.PROPERTY + '/:propertyId'} element={<OffersProperty />} />
          <Route path={APP_ROUTES.OFFERS} element={<BrokerOffers />} />
          <Route path={APP_ROUTES.WON_DEALS} element={<BrokersWonDeals />} />
          <Route path={APP_ROUTES.NOTIFICATIONS} element={<NotificationsPage />} />
          <Route path={APP_ROUTES.LOCATION} element={<LocationPage />} />
        </Route>
        <Route path={'*'} element={<Navigate to={defaultUrl} />} />
      </Routes>
    </BaseLayout>
  );
};

export default Router;
