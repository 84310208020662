import { fontFamily } from './constants';

export const theme = {
  palette: {
    redMain: '#C12026',
    redMainHover: '#B41117',
    black: '#131217',
    secondary: '#2C2C2E',
    white: 'white',
    simpleGray: '#76797D',
    lightGray: '#C4C4C4',
  },
  shadow: {
    headerShadow: '0px 2px 4px -1px #00000033, 0px -3px 5px 0px #00000024, 0px 1px 10px 0px #0000001F',
    cardShadow: '0px 0px 2px -2px rgba(41, 45, 79, 0.2),0px 0px 14px 0px rgba(0, 0, 0, 0.12)',
    buttonShadow: '0px 1px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F',
  },
  typography: {
    fontFamily,
    color: '#000000',
    button: {
      fontSize: '20px',
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: '600',
      font: `normal 600 20px/24px ${fontFamily}`,
    },
    button_secondary: {
      fontSize: '18px',
      lineHeight: '22px',
      fontStyle: 'normal',
      fontWeight: '600',
      font: `normal 600 18px/22px ${fontFamily}`,
    },
    h1: {
      fontSize: '44px',
      lineHeight: '53px',
      fontStyle: 'normal',
      fontWeight: '600',
      font: `normal 600 44px/53px ${fontFamily}`,
    },
    h1_mobile: {
      fontSize: '30px',
      lineHeight: '36px',
      fontStyle: 'normal',
      fontWeight: '600',
      font: `normal 600 30px/36px ${fontFamily}`,
    },
    h2_mobile: {
      fontSize: '28px',
      lineHeight: '34px',
      fontStyle: 'normal',
      fontWeight: '600',
      font: `normal 600 28px/34px ${fontFamily}`,
    },
    h2: {
      fontSize: '32px',
      lineHeight: '38px',
      fontStyle: 'normal',
      fontWeight: '600',
      font: `normal 600 32px/38px ${fontFamily}`,
    },
    h3: {
      fontSize: '26px',
      lineHeight: '31px',
      fontStyle: 'normal',
      fontWeight: '500',
      font: `normal 500 26px/31px ${fontFamily}`,
    },
    h3_mobile: {
      fontSize: '24px',
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: '500',
      font: `normal 500 24px/29px ${fontFamily}`,
    },
    h4: {
      fontSize: '22px',
      lineHeight: '26px',
      fontStyle: 'normal',
      fontWeight: '500',
      font: `normal 500 22px/26px ${fontFamily}`,
    },
    txt: {
      fontSize: '16px',
      lineHeight: '23px',
      fontStyle: 'normal',
      fontWeight: '500',
      font: `normal 500 16px/23px ${fontFamily}`,
    },
    headerMenu: {
      fontSize: '24px',
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: '500',
      font: `normal 500 24px/29px ${fontFamily}`,
    },
  },
  breakpoints: {
    mobile_small: '400px',
    mobile: '767px',
    tablet: '768px',
    desktop: '1350px',
    desktop_medium: '1680px',
  },
};
