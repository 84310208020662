import React, { memo } from 'react';
import { RegisterContainer } from './styles';
import { Header } from '../header';

export const RegisterLayout = memo(props => {
  return (
    <RegisterContainer>
      <Header currentUser={props.currentUser} logout={props.onLogoutClickHandler} />
      {props.children}
    </RegisterContainer>
  );
});
