import styled from 'styled-components';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export const FormLabel = styled.h1`
  font: ${props => props.theme.typography.h1.font};
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 272px;
  margin-top: 40px;
  width: 100%;
  max-width: 476px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px;
`;

export const CheckboxsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

export const SubText = styled.span`
  margin-top: 16px;
  font: ${props => props.theme.typography.txt.font};
`;

export const SubTextDown = styled.span`
  margin-bottom: 16px;
  font: ${props => props.theme.typography.txt.font};
`;

export const SignUpButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
`;

export const CheckBoxStyled = styled(Checkbox)`
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const CheckBoxLabel = styled.span`
  color: ${props => props.theme.palette.simpleGray};
  font: ${props => props.theme.typography.txt.font};
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelForFieldsBlock = styled.h4`
  font: ${props => props.theme.typography.h4.font};
`;
