import {
  CustomButton,
  CustomPagination,
  OfferCard,
  OffersContainer,
  PaginationContainer,
  PropertyAddress,
  PropertyCost,
  PropertyStatus,
} from './styles';
import { APP_ROUTES } from '../../utils/constants';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../utils/helpers';
import OfferListChangeStatus from '../offer-list-change-status';
import { statusesOffers } from '../../utils/constants';

const COUNT_PAGE = 10;
const OffersList = ({ offers }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const offersSlice = () => {
    if (!offers?.length) return [];
    return offers.slice((page - 1) * COUNT_PAGE, page * COUNT_PAGE);
  };

  const getStatusName = status => {
    const { label } = statusesOffers.find(({ value }) => value === status) || {};
    return label || '';
  };

  return (
    <>
      <OffersContainer>
        {offersSlice().map(offer => (
          <OfferCard key={offer.id}>
            <div>
              <PropertyAddress>{offer.property.address}</PropertyAddress>
              <PropertyCost>Desired Price: {formatNumber(offer.property.cost)}</PropertyCost>
              {offer.accepted_at && (
                <>
                  <PropertyStatus>Property Status: {getStatusName(offer.status)}</PropertyStatus>
                  <OfferListChangeStatus offer={offer} />
                </>
              )}
            </div>
            <div>
              <CustomButton
                onClick={() => navigate(APP_ROUTES.PROPERTY + `/${offer.property.id}`)}
                variant="contained"
                color="error">
                More details
              </CustomButton>
            </div>
          </OfferCard>
        ))}
      </OffersContainer>
      {Boolean(offers?.length) && (
        <PaginationContainer>
          <CustomPagination
            page={page}
            onChange={(e, value) => setPage(value)}
            variant="outlined"
            shape="rounded"
            count={Math.ceil(offers.length / COUNT_PAGE)}
            showFirstButton
            showLastButton
          />
        </PaginationContainer>
      )}
    </>
  );
};

export default OffersList;
