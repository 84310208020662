import axios from '../utils/axios/index';
import { API_ROUTES } from '../utils/constants';

const getPropertiesByFilter = locationString => {
  return axios.get(API_ROUTES.PROPERTY, { params: { spot: locationString } });
};

const getProperties = () => {
  return axios.get(API_ROUTES.PROPERTY);
};

const createProperty = data => {
  return axios.post(API_ROUTES.PROPERTY, data);
};

const deleteProperty = id => {
  return axios.delete(`${API_ROUTES.PROPERTY}/${id}`);
};

const getPropertyById = id => {
  return axios.get(API_ROUTES.PROPERTY + `/${id}`);
};

const updateStatusPropertyById = (id, status) => {
  return axios.patch(API_ROUTES.PROPERTY + `/${id}`, { status });
};

const PropertyService = {
  getProperties,
  getPropertiesByFilter,
  createProperty,
  deleteProperty,
  getPropertyById,
  updateStatusPropertyById,
};

export default PropertyService;
