import axios from '../utils/axios/index';
import { API_ROUTES } from '../utils/constants';

const createCompany = data => {
  let provides = [
    data.socialMedia ? 'social_media_marketing' : '',
    data.mailers ? 'mailers' : '',
    data.openHouses ? 'open_houses' : '',
    data.listingPhoto ? 'listing_photography' : '',
    data.listingVideo ? 'listing_videography' : '',
    data.drone ? 'drone_photography_video' : '',
    data.furniture ? 'furniture_staging' : '',
  ];
  return axios.post(API_ROUTES.CREATE_PROFILE, {
    company: {
      name: data.companyName,
      street_address: data.companyStreet,
      city: data.city,
      state: data.state,
      zip_code: data.zipCode,
      web_site: data.website,
    },
    license: {
      state: data.licenseState,
      number: data.licenseNumber,
    },
    about: {
      experience: data.experience,
      bio: data.bio,
      sold: data.sold,
    },
    services: {
      provides: provides.filter(item => item !== ''),
    },
  });
};

const getFullProfileInfo = () => {
  return axios.get(API_ROUTES.GET_ALL_PROFILE_INFO);
};

const getContactInfo = () => {
  return axios.get(API_ROUTES.GET_CONTACT_INFO);
};

const getAvatarById = id => {
  return axios.get(`${API_ROUTES.PROFILE}/${id}/avatar`, {
    responseType: 'blob',
  });
};

const ProfileService = {
  createCompany,
  getFullProfileInfo,
  getContactInfo,
  getAvatarById,
};
export default ProfileService;
