import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import { joiResolver } from '@hookform/resolvers/joi';
import CircularProgress from '@mui/material/CircularProgress';
import {
  FormStyled,
  FormLabel,
  FieldsContainer,
  HeadContainer,
  SignUpButton,
  CheckBoxStyled,
  CheckBoxLabel,
  SubText,
  CheckboxsContainer,
  SubTextDown,
  LabelForFieldsBlock,
  MainContainer,
} from './styles';
import { schemaFormFirstLogin } from '../../../joi/index';
import { CustomTextField } from '../../profile/broker/styles';

const FormFirstLogin = ({ loading, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schemaFormFirstLogin),
  });

  return (
    <MainContainer>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <HeadContainer>
          <FormLabel>First log in</FormLabel>
          <SubText>In order to enter the site you need to fill out the required information:</SubText>
        </HeadContainer>
        <FieldsContainer>
          <LabelForFieldsBlock>License</LabelForFieldsBlock>
          <TextField
            name="licenseState"
            label="License state"
            placeholder="License state"
            {...register('licenseState')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.licenseState?.message ? true : false}
            helperText={errors.licenseState?.message}
          />
          <TextField
            name="licenseNumber"
            label="License number"
            placeholder="License number"
            {...register('licenseNumber')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.licenseNumber?.message ? true : false}
            helperText={errors.licenseNumber?.message}
          />
        </FieldsContainer>
        <FieldsContainer>
          <LabelForFieldsBlock>Company</LabelForFieldsBlock>
          <TextField
            name="companyName"
            label="Company name"
            placeholder="Company name"
            {...register('companyName')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.companyName?.message ? true : false}
            helperText={errors.companyName?.message}
          />
          <TextField
            name="companyStreet"
            label="Company street"
            placeholder="Company street"
            {...register('companyStreet')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.companyStreet?.message ? true : false}
            helperText={errors.companyStreet?.message}
          />
          <TextField
            name="city"
            label="City"
            placeholder="City"
            {...register('city')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.city?.message ? true : false}
            helperText={errors.city?.message}
          />
          <TextField
            name="state"
            label="State"
            placeholder="State"
            {...register('state')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.state?.message ? true : false}
            helperText={errors.state?.message}
          />
          <TextField
            name="zipCode"
            label="Zip Code"
            placeholder="Zip Code"
            {...register('zipCode')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.zipCode?.message ? true : false}
            helperText={errors.zipCode?.message}
          />
          <TextField
            name="website"
            label="Website"
            placeholder="https://"
            {...register('website')}
            inputProps={{ maxLength: 50 }}
            variant="outlined"
            error={errors.website?.message ? true : false}
            helperText={errors.website?.message}
          />
        </FieldsContainer>
        <FieldsContainer>
          <LabelForFieldsBlock>About you</LabelForFieldsBlock>
          <TextField
            name="experience"
            label="Years of realtor experience"
            placeholder="Years of realtor experience"
            {...register('experience')}
            type="number"
            min="0"
            InputProps={{ min: 0, max: 100 }}
            variant="outlined"
            error={errors.experience?.message ? true : false}
            helperText={errors.experience?.message}
          />
          <CustomTextField
            name="sold"
            label="How many homes have you sold in the past year?"
            placeholder="How many homes have you sold in the past year?"
            {...register('sold')}
            type="number"
            min="0"
            InputProps={{ min: 0 }}
            variant="outlined"
            error={errors.sold?.message ? true : false}
            helperText={errors.sold?.message}
          />
          <TextField
            name="bio"
            label="Bio"
            placeholder="Bio"
            multiline
            fullWidth
            {...register('bio')}
            maxRows={10}
            minRows={5}
            inputProps={{ maxLength: 500 }}
            variant="outlined"
            error={errors.bio?.message ? true : false}
            helperText={errors.bio?.message}
          />
        </FieldsContainer>
        <CheckboxsContainer>
          <LabelForFieldsBlock>Services offered</LabelForFieldsBlock>
          <SubTextDown>(for applicable deals)</SubTextDown>
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('socialMedia')}
            label={<CheckBoxLabel>Social Media Marketing</CheckBoxLabel>}
          />
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('mailers')}
            label={<CheckBoxLabel>Mailers</CheckBoxLabel>}
          />
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('openHouses')}
            label={<CheckBoxLabel>Open Houses</CheckBoxLabel>}
          />
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('listingPhoto')}
            label={<CheckBoxLabel>Listing Photography</CheckBoxLabel>}
          />
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('listingVideo')}
            label={<CheckBoxLabel>Listing Videography</CheckBoxLabel>}
          />
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('drone')}
            label={<CheckBoxLabel>Drone Photography/Video</CheckBoxLabel>}
          />
          <FormControlLabel
            control={<CheckBoxStyled />}
            {...register('furniture')}
            label={<CheckBoxLabel>Furniture Staging</CheckBoxLabel>}
          />
        </CheckboxsContainer>
        <FieldsContainer>
          <SignUpButton disabled={loading} type="submit" variant="contained">
            {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Save info'}
          </SignUpButton>
        </FieldsContainer>
      </FormStyled>
    </MainContainer>
  );
};

export default memo(FormFirstLogin);
