import axios from 'axios';
import { authHeader } from '../../guard/user';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api/v1/`,
});

instance.interceptors.request.use(config => {
  config.headers['Authorization'] = `${process.env.REACT_APP_BASE_TOKEN}`;
  config.headers['X-Bearer-Token'] = authHeader();
  return config;
});

export default instance;
