import axios from '../utils/axios/index';
import { API_ROUTES } from '../utils/constants';

const getNotifications = query => {
  return axios.get(API_ROUTES.NOTIFICATION + '/list', { params: query });
};

const getCountNotification = () => {
  return axios.get(API_ROUTES.NOTIFICATION + '/count_messages');
};

const setReadNotification = id => {
  return axios.post(`${API_ROUTES.NOTIFICATION}/read/${id}`);
};

const NotificationService = {
  getNotifications,
  setReadNotification,
  getCountNotification,
};

export default NotificationService;
