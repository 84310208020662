import React, { memo } from 'react';
import { OfferContainer, OfferInfo, RedButton, RedText } from './styles';
import { format } from 'date-fns';

const OfferCard = ({ offer, setChooseOffer }) => {
  const { broker, total_commission, terms, created_at, commission_type } = offer;
  const commissionType = commission_type === 'flat' ? '$' : '%';

  return (
    <OfferContainer>
      <div>Broker: {`${broker.first_name} ${broker.last_name[0]}***`}</div>
      <OfferInfo>
        <span>Date: {format(new Date(created_at), 'MM/dd/yyyy')}</span>
        <span>
          Location: {broker.profile?.company?.city}, {broker.profile?.company?.state}
        </span>
        <span>
          Commissions:
          <RedText>{`${total_commission} ${commissionType}`}</RedText>
        </span>
        <span>
          Terms: <RedText>{terms} Months</RedText>
        </span>
      </OfferInfo>
      <RedButton onClick={() => setChooseOffer(offer)} variant="contained" color="error">
        View Details
      </RedButton>
    </OfferContainer>
  );
};

export default memo(OfferCard);
