import React from 'react';
import { useSelector } from 'react-redux';
import BrokerProfile from './broker';
import HomeOwnerProfile from './homeOwner';

const ProfilePage = () => {
  const { user } = useSelector(state => state.auth);

  if (!user) return <></>;
  if (user.role.code === 'homeowner') return <HomeOwnerProfile />;
  return <BrokerProfile />;
};

export default ProfilePage;
