import styled from 'styled-components';
import { Button } from '@mui/material';
import { ReactComponent as Arrow } from '../../../icons/keyboard-backspace.svg';
import { ReactComponent as Plus } from '../../../icons/plus.svg';

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.h1`
  font: ${props => props.theme.typography.h1_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${props => props.theme.typography.h1.font};
  }
`;

export const BackArrow = styled(Arrow)`
  width: 32px;
  height: 32px;
`;

export const PlusIcon = styled(Plus)`
  width: 16px;
  height: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledImg = styled.img`
  width: 272px;
  height: 272px;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 370px;
    height: 370px;
  }
`;

export const Text = styled.h4`
  font: ${props => props.theme.typography.txt.font};
`;

export const TextRed = styled.h4`
  color: ${props => props.theme.palette.redMain};
  font: ${props => props.theme.typography.button.font};
`;

export const AddMenthodButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button_secondary.font};
    padding: 16px 20px;
    display: flex;
    gap: 14px;
    font-weight: 500;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 16px 28px;
      font: ${props => props.theme.typography.button_secondary.font};
    }
  }
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  text-align: center;
`;

export const TextSmContainer = styled.span`
  display: flex;
  align-items: baseline;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
`;

export const BtnContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;
