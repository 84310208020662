import React from 'react';
import { CircleContainer } from './styles';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <CircleContainer>
      <CircularProgress style={{ color: '#C12026', marginTop: 300 }} />
    </CircleContainer>
  );
};

export default Loader;
