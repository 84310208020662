import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { APP_ROUTES, TOAST_MESSAGES } from '../../utils/constants';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { checkPasswordRecovery } from '../../slices/auth';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  FormStyled,
  LogoIcon,
  FormLabel,
  FieldsContainer,
  HeadContainer,
  LoginButton,
  LinkStyledBack,
  BackIcon,
} from './styles';
import { schemaNewPassword } from '../../joi';

export const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemaNewPassword),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const token = params.verification_token;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailForForgot, loading } = useSelector(state => state.auth);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = data => {
    if (token && emailForForgot) {
      dispatch(
        checkPasswordRecovery({ verificationToken: token, email: emailForForgot, password: data.password })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          navigate(APP_ROUTES.LOGIN);
          toast.success(TOAST_MESSAGES.PASSWORD_CHANGE_SUCCESS);
        }
      });
    }
  };

  useEffect(() => {
    if (!emailForForgot) {
      navigate(APP_ROUTES.MAIN);
    }
  }, [emailForForgot]);

  return (
    <>
      {emailForForgot && (
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <LogoIcon />
          <HeadContainer>
            <FormLabel>
              <LinkStyledBack to={APP_ROUTES.LOGIN}>
                <BackIcon />
              </LinkStyledBack>
              Enter a new password
            </FormLabel>
          </HeadContainer>
          <FieldsContainer>
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              placeholder="Password"
              {...register('password')}
              inputProps={{ maxLength: 100 }}
              error={errors.password?.message ? true : false}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? (
                        <VisibilityOff style={{ fill: 'black' }} />
                      ) : (
                        <Visibility style={{ fill: 'black' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="confirmPassword"
              label="Confirm password"
              placeholder="Confirm password"
              {...register('confirmPassword')}
              inputProps={{ maxLength: 100 }}
              error={errors.confirmPassword?.message ? true : false}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? (
                        <VisibilityOff style={{ fill: 'black' }} />
                      ) : (
                        <Visibility style={{ fill: 'black' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoginButton disabled={loading} type="submit" variant="contained">
              {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Save'}
            </LoginButton>
          </FieldsContainer>
        </FormStyled>
      )}
    </>
  );
};
