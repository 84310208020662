import styled from 'styled-components';
import { Button, Pagination } from '@mui/material';
import { ReactComponent as KeyboardBackSpace } from '../../icons/keyboard-backspace.svg';
import { ReactComponent as DoneAll } from '../../icons/done_all.svg';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  max-width: 1368px;
  margin: 0 auto;
  width: 100%;
  padding: 24px;
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 52.8px;
  letter-spacing: 0.15px;
  width: fit-content;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 30px;
    line-height: 36px;
    align-items: flex-start;
  }
`;

export const KeyboardBackSpaceIcon = styled(KeyboardBackSpace)`
  margin-right: 8px;
`;

export const RedButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 12px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 30px;
  }

  &.desktop {
    &.MuiButton-root {
      margin-left: auto;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      display: none;
    }
  }

  &.mobile {
    &.MuiButton-root {
      margin-top: 24px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      display: none;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const CustomPagination = styled(Pagination)`
  .MuiButtonBase-root {
    border-radius: 100px;
    font-size: 14px;
    &.Mui-selected {
      background-color: #b41117;
      color: white;
    }
  }
`;

export const OffersContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const RedText = styled.span`
  color: #d32f2f;
`;

export const OfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: 13px;
`;

export const PropertyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 24px;
`;

export const PropertyPrice = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: 0.15px;
  white-space: pre-wrap;
  margin-bottom: 24px;

  @media (min-width: 500px) and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    white-space: nowrap;
  }
`;

export const PropertyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 562px;
  column-gap: 100px;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: unset;
    width: 100%;
  }
`;

export const PropertyContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleValue = styled.span`
  font-weight: 700;
  letter-spacing: 0.15px;
`;

export const PropertyCreatedAt = styled.div`
  color: #76797d;
  font-size: 16px;
`;

export const YourOffer = styled.div`
  font-size: 32px;
  margin-bottom: 24px;
`;

export const YourOfferContainer = styled.div`
  border-radius: 12px;
  padding: 32px;
  margin-bottom: 32px;
  box-shadow:
    0px 0px 2px -2px rgba(41, 45, 79, 0.2),
    0px 0px 14px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  @media (max-width: 400px) {
    padding: 12px;
  }
`;

export const OfferText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15px;
`;

export const BrokerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const BrokerName = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.15px;
`;

export const BrokerLocation = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
`;

export const AvailableServicesText = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const AdditionalText = styled.p`
  font-size: 11px;
  line-height: 20px;
  font-weight: 300;
`;

export const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ServicesContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
`;

export const DoneAllIcon = styled(DoneAll)`
  width: 21px;
  height: 21px;
`;

export const ServiceCard = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 12px;
  gap: 6px;
`;

export const BrokerImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 120px;
`;

export const BrokerNotFoundImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 120px;
  border: 1px solid grey;
`;

export const BrokerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`;

export const PropertyAdditionalText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  color: #76797d;
  font-size: 26px;
  line-height: 31.2px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 24px;
    line-height: 28.8px;
  }
`;

export const Ellipse = styled.div`
  margin-right: 3px;
  width: 8px;
  height: 8px;
  background-color: #76797d;
  border-radius: 100px;
`;
