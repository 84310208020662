import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from './core/router';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { StyledToastContainer } from './utils/toast';
import store from './store';
import { theme } from './utils/theme';
import GlobalStyle from './globalStyles';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <StyledToastContainer hideProgressBar autoClose={2000} pauseOnHover theme="dark" />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
