import { API_ROUTES, callbackAccessRecoveryURL } from '../utils/constants';
import axios from '../utils/axios/index';
import { callbackVerifyURL } from '../utils/constants/index';

const register = (firstName, lastName, email, phone, password, role, howYouFindUs) => {
  return axios.post(API_ROUTES.SIGN_UP, {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    password,
    role,
    came_from: howYouFindUs,
  });
};

const createAccessToken = (email, password) => {
  return axios.post(API_ROUTES.CREATE_ACCESS_TOKEN, {
    user: email,
    password,
  });
};

const sendEmailVerification = token => {
  return axios.post(
    API_ROUTES.SEND_EMAIL_VERIFICATION,
    {
      callback_url: callbackVerifyURL,
    },
    {
      headers: {
        'X-BEARER-TOKEN': token,
      },
    }
  );
};

const checkEmailVerification = verificationToken => {
  return axios.post(API_ROUTES.CHECK_EMAIL_VERIFICATION, {
    verification_token: verificationToken,
  });
};

const sendPasswordRecovery = email => {
  return axios.post(API_ROUTES.SEND_PASSWORD_RECOVERY, {
    callback_url: callbackAccessRecoveryURL,
    email,
  });
};

const checkPasswordRecovery = (verificationToken, email, password) => {
  return axios.post(API_ROUTES.CHECK_PASSWORD_RECOVERY, {
    verification_token: verificationToken,
    email,
    password,
  });
};

const reConfirmEmailVerification = verificationToken => {
  return axios.post(API_ROUTES.RE_CONFIRM_EMAIL_VERIFICATION, {
    verification_token: verificationToken,
    callback_url: callbackVerifyURL,
  });
};

const getUserInfo = () => {
  return axios.get(API_ROUTES.ACCOUNT + '/get_current');
};

const updateAccount = data => {
  return axios.post(API_ROUTES.ACCOUNT + '/update', data);
};

const authService = {
  register,
  getUserInfo,
  updateAccount,
  createAccessToken,
  sendEmailVerification,
  checkEmailVerification,
  sendPasswordRecovery,
  checkPasswordRecovery,
  reConfirmEmailVerification,
};

export default authService;
