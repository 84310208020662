import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 1808px;
  padding: 38px 32px;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 70%;
  }
`;

export const Title = styled.div`
  font-size: 44px;
  font-weight: 600;
  line-height: 52.8px;
  letter-spacing: 0.15px;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const StyledImg = styled.img`
  width: 234px;
  height: 221px;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 427px;
    height: 403px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.palette.black};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${props => props.theme.typography.txt.font};
  }
`;

export const TextRed = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.palette.redMain};
  text-decoration: underline;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${props => props.theme.typography.txt.font};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 269px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 4px;
    width: 330px;
  }
`;
