import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { Select } from '../offers-list/styles';
import { changeOfferStatus } from '../../slices/offer';
import { statusesOffers } from '../../utils/constants';
import { CustomButton, Wrapper } from './styles';

const OfferListChangeStatus = ({ offer }) => {
  const [status, setStatus] = useState(offer.status || '');
  const dispatch = useDispatch();

  const changeStatus = () => {
    dispatch(changeOfferStatus({ id: offer.id, status: status }));
  };

  return (
    <Wrapper>
      <Select
        select
        fullWidth
        size="small"
        variant="outlined"
        defaultValue={offer.status}
        onChange={e => setStatus(e.target.value)}>
        {statusesOffers.map(({ label, value, visible }) => (
          <MenuItem sx={{ display: !visible ? 'none' : 'flex' }} key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <CustomButton disabled={offer.status === status} onClick={changeStatus} variant="contained" color="error">
        Save
      </CustomButton>
    </Wrapper>
  );
};

export default OfferListChangeStatus;
