import React, { useState } from 'react';
import { CardCheckBox, CardContainer, CheckIcon, UnCheckedIcon, VisaIcon, MasterCardIcon } from './styles';

export const PaymentCard = ({ card, choosePayment }) => {
  const [isHoverCheckBox, setHoverCheckBox] = useState(false);
  const imgCard = type => {
    if (type === 'visa') return <VisaIcon />;
    return <MasterCardIcon />;
  };

  const tittleCard = type => {
    if (type === 'visa') return 'Visa';
    return 'MasterCard';
  };

  const setDefaultPay = () => {
    if (!card.is_preferred) choosePayment(card.id);
  };

  return (
    <CardContainer
      onClick={setDefaultPay}
      onMouseOver={() => setHoverCheckBox(true)}
      onMouseLeave={() => setHoverCheckBox(false)}>
      {imgCard(card.brand)}
      <div>
        {tittleCard(card.brand)} ***{card.last4}
      </div>
      {(card.is_preferred || isHoverCheckBox) && (
        <CardCheckBox
          icon={<UnCheckedIcon sx={{ fontSize: 28 }} />}
          checkedIcon={<CheckIcon sx={{ fontSize: 28 }} />}
          disabled
          checked={card.is_preferred}
        />
      )}
    </CardContainer>
  );
};

export default PaymentCard;
