import styled from 'styled-components';
import { Button } from '@mui/material';

export const OfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding: 24px;
  border-radius: 12px;
  box-shadow:
    0px 0px 2px -2px rgba(41, 45, 79, 0.2),
    0px 0px 14px 0px rgba(0, 0, 0, 0.12);
`;

export const RedText = styled.span`
  color: #d32f2f;
`;

export const OfferInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 64px;
`;

export const RedButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 12px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }
`;
