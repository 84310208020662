import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MainContainer,
  BlockInfo,
  CustomTextField,
  FieldsContainer,
  InformationContainer,
  TitleContainer,
  LinkContainer,
  KeyboardBackSpaceIcon,
  RedButton,
} from './styles';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { schemaUpdateProfile } from '../../../joi';
import { updateAccount } from '../../../slices/auth';

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schemaUpdateProfile),
    defaultValues: { first_name: user.first_name, last_name: user.last_name, phone: user.phone },
  });

  const submit = data => {
    const updateData = {};
    for (const key in data) {
      if (data[key] !== user[key]) updateData[key] = data[key];
    }
    if (Object.keys(updateData).length) dispatch(updateAccount(updateData));
  };

  return (
    <MainContainer>
      <LinkContainer to={'/'}>
        <KeyboardBackSpaceIcon />
        Profile
      </LinkContainer>
      <form onSubmit={handleSubmit(submit)}>
        <InformationContainer>
          <BlockInfo>
            <TitleContainer>My profile information</TitleContainer>
            <FieldsContainer>
              <CustomTextField
                {...register('first_name')}
                label="First name"
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message}
              />
              <CustomTextField
                {...register('last_name')}
                label="Last name"
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message}
              />
              <CustomTextField name="email" label="Email" disabled value={user.email || '-'} />
              <CustomTextField
                {...register('phone')}
                label="Phone"
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
              />
            </FieldsContainer>
          </BlockInfo>
        </InformationContainer>
        <RedButton type="submit" disabled={Object.keys(errors).length} fullWidth variant="contained" color="error">
          Save changes
        </RedButton>
      </form>
    </MainContainer>
  );
};

export default Profile;
