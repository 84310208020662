import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';
import NotificationService from '../services/notification.service';

// const properties = JSON.parse(localStorage.getItem('properties')) ? JSON.parse(localStorage.getItem('properties')) : [];

export const setReadStatusNotification = createAsyncThunk('property/set-read-notification', async (id, thunkAPI) => {
  try {
    const response = await NotificationService.setReadNotification(id);

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllNotifications = createAsyncThunk('notifications/get-list-notifications', async (data, thunkAPI) => {
  try {
    const response = await NotificationService.getNotifications(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCountNotifications = createAsyncThunk(
  'notifications/get-count-notifications',
  async (data, thunkAPI) => {
    try {
      const response = await NotificationService.getCountNotification();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  notifications: null,
  count: null,
};

const propertySlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setReadStatusNotification.fulfilled, (state, action) => {
        if (state.notifications.length) {
          state.notifications.forEach(item => {
            if (item.id === action.payload.id) {
              item.is_read = true;
            }
          });
        }
        state.loading = false;
      })
      .addCase(setReadStatusNotification.pending, state => {
        state.loading = true;
      })
      .addCase(setReadStatusNotification.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_SET_READ_NOTIFICATION);
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = false;
      })
      .addCase(getAllNotifications.pending, state => {
        state.loading = true;
      })
      .addCase(getAllNotifications.rejected, state => {
        state.loading = false;
      })

      .addCase(getCountNotifications.fulfilled, (state, action) => {
        state.count = action.payload;
      });
  },
});

export default propertySlice.reducer;
