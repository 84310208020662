import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../icons/logo.svg';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Radio } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import { Link } from 'react-router-dom';

export const LogoIcon = styled(Logo)`
  width: 80%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 410px;
  }
`;

export const LinkStyled = styled(Link)`
  font: ${props => props.theme.typography.txt.font};
  color: ${props => props.theme.palette.redMain};
  cursor: pointer;
  text-decoration: underline;
`;

export const LinkStyledBack = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.palette.black};
`;

export const FormLabel = styled.h2`
  font: ${props => props.theme.typography.h2_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 36px;
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 272px;
  width: 100%;
  max-width: 476px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px;

    margin-bottom: 30px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 26px;
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RoleContainer = styled.div`
  display: flex;
  padding-left: 9px;
`;

export const CheckIcon = styled(CheckCircleIcon)`
  color: ${props => props.theme.palette.redMain};
`;

export const UnCheckedIcon = styled(RadioButtonUncheckedIcon)`
  color: ${props => props.theme.palette.redMain};
`;

export const RoleText = styled.span`
  margin-top: 32px;
  margin-bottom: 8px;
  font: ${props => props.theme.typography.h4.font};
`;

export const SignUpButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
`;

export const CheckBoxStyled = styled(Checkbox)`
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const CheckBoxLabel = styled.span`
  color: ${props => props.theme.palette.simpleGray};
  font: ${props => props.theme.typography.txt.font};
  width: 100%;
`;

export const RadioStyled = styled(Radio)`
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const DoneIcon = styled(CheckCircleOutlineSharpIcon)`
  &.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    color: #07bc0c;
  }
`;

export const SuccessRegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-left: 20%;
    padding-right: 20%;
  }
`;

export const DoneBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const DoneBlockText = styled.span`
  font: ${props => props.theme.typography.h4.font};
  margin-left: 10px;
`;
