import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';
import PropertyService from '../services/property.service';

// const properties = JSON.parse(localStorage.getItem('properties')) ? JSON.parse(localStorage.getItem('properties')) : [];

export const getAllPropertiesByFilter = createAsyncThunk(
  'property/get-properties-by-filter',
  async (data, thunkAPI) => {
    try {
      const response = await PropertyService.getPropertiesByFilter(data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProperties = createAsyncThunk('property/get-all-properties', async (data, thunkAPI) => {
  try {
    const response = await PropertyService.getProperties();

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteProperty = createAsyncThunk('property/delete', async (id, thunkAPI) => {
  try {
    const response = await PropertyService.deleteProperty(id);

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const createProperty = createAsyncThunk('property/create', async (data, thunkAPI) => {
  try {
    const response = await PropertyService.createProperty(data);

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const getPropertyById = createAsyncThunk('property/get-properties-by-id', async (data, thunkAPI) => {
  try {
    const response = await PropertyService.getPropertyById(data);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateStatusPropertyById = createAsyncThunk(
  'property/update-status-property-by-id',
  async (data, thunkAPI) => {
    try {
      const { id, status } = data;
      const response = await PropertyService.updateStatusPropertyById(id, status);

      return response.data;
    } catch (error) {
      const message = error?.response?.data?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: false,
  property: null,
  properties: null,
};

const propertySlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setProperties: state => {
      state.properties = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllPropertiesByFilter.fulfilled, (state, action) => {
        state.properties = action.payload;
        state.loading = false;
      })
      .addCase(getAllPropertiesByFilter.pending, state => {
        state.loading = true;
      })
      .addCase(getAllPropertiesByFilter.rejected, state => {
        state.loading = false;
        toast.error(TOAST_MESSAGES.ERROR_GET_PROPERTIES);
      })
      .addCase(getAllProperties.fulfilled, (state, action) => {
        state.properties = action.payload;
        state.loading = false;
      })
      .addCase(getAllProperties.pending, state => {
        state.loading = true;
      })
      .addCase(getAllProperties.rejected, state => {
        state.loading = false;
        toast.error(TOAST_MESSAGES.ERROR_GET_PROPERTIES);
      })
      .addCase(createProperty.fulfilled, state => {
        state.loading = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(createProperty.pending, state => {
        state.loading = true;
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_CREATE_PROPERTY);
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        //localStorage.setItem('step', JSON.stringify(2));
        if (state.properties !== undefined) {
          state.properties = state.properties.filter(property => property.id !== action.payload.id);
        }
        state.loading = false;
        toast.success(TOAST_MESSAGES.DELETE_SUCCESS);
      })
      .addCase(deleteProperty.pending, state => {
        state.loading = true;
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_DELETE_PROPERTY);
      })
      .addCase(getPropertyById.fulfilled, (state, action) => {
        state.property = action.payload;
        state.loading = false;
      })
      .addCase(getPropertyById.pending, state => {
        state.loading = true;
      })
      .addCase(getPropertyById.rejected, state => {
        state.loading = false;
      })
      .addCase(updateStatusPropertyById.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateStatusPropertyById.pending, state => {
        state.loading = true;
      })
      .addCase(updateStatusPropertyById.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload);
      });
  },
});

export const { setProperties } = propertySlice.actions;

export default propertySlice.reducer;
