import * as React from 'react';
import { Dialog } from '@mui/material';
import {
  DialogTitleStyled,
  CloseIcon,
  DialogContentStyled,
  ListStyled,
  FormStyled,
  FieldsContainer,
  LabelForFieldsBlock,
  ButtonsGroup,
  CreateButton,
  DeleteButton,
  TextFieldStyled,
  TextForFieldsBlock,
} from './styles';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { schemaCreatePropertyContacts } from '../../joi/index';

export const PropertyDialog = props => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schemaCreatePropertyContacts),
  });

  const onSubmit = data => {
    props.handleSubmitForm(data);
  };
  return (
    <Dialog
      open={props.status}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: '12px',
          maxWidth: '610px',
        },
      }}>
      <DialogTitleStyled>Publish Listing</DialogTitleStyled>
      <CloseIcon onClick={props.handleClose} />
      <DialogContentStyled>
        <ListStyled>
          <li>Brokers will submit offers defining their commission rate and terms</li>
        </ListStyled>
        <FormStyled>
          <LabelForFieldsBlock>Contact details</LabelForFieldsBlock>
          <TextForFieldsBlock>
            These are the contact details your new broker will use to get in touch with you
          </TextForFieldsBlock>
          <FieldsContainer>
            <TextFieldStyled
              name="firstName"
              label="First name"
              placeholder="First name"
              {...register('firstName')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.firstName?.message ? true : false}
              helperText={errors.firstName?.message}
            />
            <TextFieldStyled
              name="lastName"
              label="Last name"
              placeholder="Last name"
              {...register('lastName')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.lastName?.message ? true : false}
              helperText={errors.lastName?.message}
            />
          </FieldsContainer>
          <FieldsContainer>
            <TextFieldStyled
              name="email"
              label="Email"
              placeholder="Email"
              {...register('email')}
              inputProps={{ maxLength: 120 }}
              error={errors.email?.message ? true : false}
              helperText={errors.email?.message}
            />
            <TextFieldStyled
              name="phone"
              label="Phone"
              placeholder="Phone"
              {...register('phone')}
              inputProps={{ maxLength: 20 }}
              error={errors.phone?.message ? true : false}
              helperText={errors.phone?.message}
            />
          </FieldsContainer>
        </FormStyled>
      </DialogContentStyled>
      <ButtonsGroup>
        <DeleteButton onClick={props.handleClose}>Cancel</DeleteButton>
        <CreateButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Start listing
        </CreateButton>
      </ButtonsGroup>
    </Dialog>
  );
};
