import React from 'react';
import logo from '../../../icons/empty-search.svg';
import { Container, Title, ImageContainer, StyledImg, Text, TextRed, TextContainer } from '../style';
import { APP_ROUTES } from '../../../utils/constants';

const EmptyDeals = () => {
  return (
    <Container>
      <Title>Won deals</Title>
      <ImageContainer>
        <StyledImg src={logo} />
        <TextContainer>
          <Text>
            You haven't made a single Offer yet, go to <TextRed to={APP_ROUTES.MAIN}>Deals</TextRed> and participate in
            the listing.
          </Text>
        </TextContainer>
      </ImageContainer>
    </Container>
  );
};

export default EmptyDeals;
