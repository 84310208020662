import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { joiResolver } from '@hookform/resolvers/joi';
import { APP_ROUTES, TOAST_MESSAGES, privacyURL, rulesURL, termsURL } from '../../../utils/constants';
import { createAccessToken, signUp, sendEmailVerification } from '../../../slices/auth';
import CircularProgress from '@mui/material/CircularProgress';
import {
  FormStyled,
  LogoIcon,
  FormLabel,
  FieldsContainer,
  HeadContainer,
  RoleContainer,
  CheckIcon,
  UnCheckedIcon,
  RoleText,
  SignUpButton,
  CheckBoxStyled,
  CheckBoxLabel,
  RadioStyled,
  DoneIcon,
  SuccessRegistrationContainer,
  DoneBlock,
  DoneBlockText,
  LinkStyled,
  LinkStyledBack,
} from './styles';
import { schemaFormSignUp } from '../../../joi';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BackIcon } from '../../createProperty/styles';

function FormSignUp() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schemaFormSignUp),
  });
  const dispatch = useDispatch();
  const { loading, user } = useSelector(state => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = async data => {
    setEmail(data.email);
    dispatch(
      signUp({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        password: data.password,
        role: data.role,
        howYouFindUs: data.howYouFindUs,
      })
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(createAccessToken({ email: res.payload.email, password: data.password, loginStatus: false })).then(
          response => {
            if (response.meta.requestStatus === 'fulfilled') {
              dispatch(sendEmailVerification(response.payload.data.access_token));
            }
          }
        );
      }
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    if (errors.role?.message) {
      toast.error(TOAST_MESSAGES.ERROR_ROLE);
    }
    if (errors.privacyPolicy?.message) {
      toast.error(TOAST_MESSAGES.ERROR_POLICY);
    }
  }, [errors.role, errors.privacyPolicy]);

  return (
    <>
      {user ? (
        <SuccessRegistrationContainer>
          <LogoIcon />
          <DoneBlock>
            <DoneIcon />
            <DoneBlockText>Thank you for signing up!</DoneBlockText>
          </DoneBlock>
          <p>
            Please check your email {email} and go to the link for verification. If you don’t see the email in your
            inbox within a few minutes, please check your spam or junk folder. Sometimes, email filters may divert our
            messages there. If you still can’t find it, please contact our{' '}
            <LinkStyled to={'https://fetchabroker.com/support/'}>support</LinkStyled>.
          </p>
        </SuccessRegistrationContainer>
      ) : (
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <LogoIcon />
          <HeadContainer>
            <FormLabel>
              <LinkStyledBack to={APP_ROUTES.LOGIN}>
                <BackIcon />
              </LinkStyledBack>
              Sign up
            </FormLabel>
            <RoleText>Select a role</RoleText>
            <RoleContainer>
              <Controller
                rules={{ required: true }}
                control={control}
                name="role"
                defaultValue={''}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="broker"
                        control={
                          <RadioStyled
                            name="radio-buttons-group"
                            icon={<UnCheckedIcon />}
                            checkedIcon={<CheckIcon />}
                          />
                        }
                        label="Broker"
                      />
                      <FormControlLabel
                        value="homeowner"
                        control={
                          <RadioStyled
                            name="radio-buttons-group"
                            icon={<UnCheckedIcon />}
                            checkedIcon={<CheckIcon />}
                          />
                        }
                        label="Homeowner"
                      />
                    </RadioGroup>
                  );
                }}
              />
            </RoleContainer>
          </HeadContainer>
          <FieldsContainer>
            <TextField
              name="First Name"
              label="First name"
              placeholder="First name"
              {...register('firstName')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.firstName?.message ? true : false}
              helperText={errors.firstName?.message}
            />
            <TextField
              name="lastName"
              label="Last name"
              placeholder="Last name"
              {...register('lastName')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.lastName?.message ? true : false}
              helperText={errors.lastName?.message}
            />
            <TextField
              name="email"
              label="Email"
              placeholder="Email"
              {...register('email')}
              inputProps={{ maxLength: 120 }}
              error={errors.email?.message ? true : false}
              helperText={errors.email?.message}
            />
            <TextField
              name="phone"
              label="Phone"
              placeholder="Phone"
              {...register('phone')}
              inputProps={{ maxLength: 20 }}
              error={errors.phone?.message ? true : false}
              helperText={errors.phone?.message}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              placeholder="Password"
              {...register('password')}
              inputProps={{ maxLength: 100 }}
              error={errors.password?.message ? true : false}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? (
                        <VisibilityOff style={{ fill: 'black' }} />
                      ) : (
                        <Visibility style={{ fill: 'black' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="confirmPassword"
              label="Confirm password"
              placeholder="Confirm password"
              {...register('confirmPassword')}
              inputProps={{ maxLength: 100 }}
              error={errors.confirmPassword?.message ? true : false}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? (
                        <VisibilityOff style={{ fill: 'black' }} />
                      ) : (
                        <Visibility style={{ fill: 'black' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name="howYouFindUs"
              label="How did you find us?"
              placeholder="How did you find us?"
              {...register('howYouFindUs')}
              inputProps={{ maxLength: 120 }}
              error={errors.howYouFindUs?.message ? true : false}
              helperText={errors.howYouFindUs?.message}
            />
            <FormControlLabel
              control={<CheckBoxStyled />}
              {...register('privacyPolicy')}
              label={
                <CheckBoxLabel>
                  By signing up you agree to our <LinkStyled to={rulesURL}>rules</LinkStyled>,{' '}
                  <LinkStyled to={termsURL}>terms</LinkStyled>, and{' '}
                  <LinkStyled to={privacyURL}>privacy policy</LinkStyled>
                </CheckBoxLabel>
              }
            />
            <SignUpButton disabled={loading} type="submit" variant="contained">
              {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Sign up'}
            </SignUpButton>
          </FieldsContainer>
        </FormStyled>
      )}
    </>
  );
}

export default FormSignUp;
