import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OfferService from '../services/offer.service';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';
import { getPropertyById } from './property';

export const getOffers = createAsyncThunk('offers/get-all', async (data, thunkAPI) => {
  try {
    const response = await OfferService.getOffers();

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const acceptOffer = createAsyncThunk('accept/offer', async (data, thunkAPI) => {
  try {
    const response = await OfferService.acceptOffer(data);

    thunkAPI.dispatch(getPropertyById(response.data.property.id));
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const createOffer = createAsyncThunk('create/offer', async (data, thunkAPI) => {
  try {
    const response = await OfferService.createOffer(data);

    thunkAPI.dispatch(getPropertyById(response.data.property.id));
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteOffer = createAsyncThunk('delete/offer', async (data, thunkAPI) => {
  try {
    const response = await OfferService.deleteOffer(data);

    thunkAPI.dispatch(getPropertyById(response.data.property.id));
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const changeOfferStatus = createAsyncThunk('change-status/offer', async (data, thunkAPI) => {
  try {
    const response = await OfferService.changeOfferStatus(data.id, data.status);

    thunkAPI.dispatch(getOffers());
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  loading: false,
  offers: null,
};

const offerSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOffers.fulfilled, (state, action) => {
        state.offers = action.payload;
        state.loading = false;
      })
      .addCase(getOffers.pending, state => {
        state.loading = true;
      })
      .addCase(getOffers.rejected, state => {
        state.loading = false;
      })

      .addCase(acceptOffer.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(acceptOffer.pending, state => {
        state.loading = true;
      })
      .addCase(acceptOffer.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_ACCEPT_OFFER);
      })

      .addCase(deleteOffer.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(deleteOffer.pending, state => {
        state.loading = true;
      })
      .addCase(deleteOffer.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_RETRACT_OFFER);
      })

      .addCase(createOffer.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(TOAST_MESSAGES.SUCCESS);
      })
      .addCase(createOffer.pending, state => {
        state.loading = true;
      })
      .addCase(createOffer.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_CREATE_OFFER);
      });
  },
});

export default offerSlice.reducer;
