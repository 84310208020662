import React, { useEffect, useMemo } from 'react';
import { MainContainer } from './style';
import EmptyOffer from './emptyOffer';
import { useDispatch, useSelector } from 'react-redux';
import { getOffers } from '../../slices/offer';
import Loader from '../../components/loader';
import OffersList from '../../components/offers-list';
import { Title } from '../brokersWonDeals/style';

const BrokerOffers = () => {
  const dispatch = useDispatch();
  const { loading, offers } = useSelector(state => state.offers);

  useEffect(() => {
    dispatch(getOffers());
  }, []);

  const noAcceptedOffers = useMemo(() => {
    if (!offers) return [];
    return offers.filter(offer => !offer.accepted_at);
  }, [offers]);

  if (loading || !offers) return <Loader />;

  return (
    <MainContainer>
      {noAcceptedOffers.length === 0 ? (
        <EmptyOffer />
      ) : (
        <>
          <Title>Offers</Title>
          <OffersList offers={noAcceptedOffers} />
        </>
      )}
    </MainContainer>
  );
};

export default BrokerOffers;
