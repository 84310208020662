import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  ButtonsContainer,
  CloseIcon,
  CustomButton,
  CustomModal,
  HeadContainer,
  HeadTitle,
  MainContainer,
  CheckBoxLabel,
  CustomCheckBox,
  ContentContainer,
  CustomTextField,
  ContainerInputs,
  FormSelectContainer,
} from './styles';
import { joiResolver } from '@hookform/resolvers/joi';
import { schemaSubmitOffer } from '../../../../joi';
import { InputLabel } from '@mui/material';

const ModalSubmitOffer = ({ open, closeFun, submit }) => {
  const {
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      buyersCommission: 1,
      sellerCommission: 1,
      typeCommission: 'percent',
      totalCommission: 2,
      terms: 3,
      acceptRules: false,
    },
    resolver: joiResolver(schemaSubmitOffer),
  });

  const textCheckBoxLabel = () => {
    const rules = (
      <a style={{ color: '#131217' }} href={'https://fetchabroker.com/rules/'}>
        rules
      </a>
    );

    return <>You agree to our {rules} and will be charged a deal fee if this homeowner accepts</>;
  };

  const calculateTotalCommission = () => {
    const total = Number(watch('buyersCommission')) + Number(watch('sellerCommission'));
    setValue('totalCommission', total, { shouldValidate: true });
  };

  const inputProps =
    watch('typeCommission') === 'percent' ? { decimalScale: 2, suffix: ' %' } : { decimalScale: 2, prefix: '$ ' };

  return (
    <CustomModal
      open={open}
      onClose={closeFun}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <MainContainer>
        <form onSubmit={handleSubmit(submit)}>
          <HeadContainer>
            <HeadTitle>Submit Your Offer</HeadTitle>
            <div onClick={closeFun}>
              <CloseIcon />
            </div>
          </HeadContainer>

          <ContentContainer>
            <FormSelectContainer>
              <InputLabel id="select-label">Commission Type</InputLabel>
              <Select
                fullWidth
                id="select"
                labelId="select-label"
                defaultValue={'percent'}
                value={watch('typeCommission')}
                label="Commission Type"
                onChange={e => setValue('typeCommission', e.target.value)}>
                <MenuItem value={'flat'}>Flat Rate</MenuItem>
                <MenuItem value={'percent'}>Percentage Rate</MenuItem>
              </Select>
            </FormSelectContainer>

            <ContainerInputs>
              <Controller
                name="buyersCommission"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    customInput={CustomTextField}
                    {...inputProps}
                    decimalSeparator="."
                    allowNegative={false}
                    fullWidth
                    error={!!errors.buyersCommission?.message}
                    helperText={errors.buyersCommission?.message?.replace(
                      '"buyersCommission"',
                      'Buyers Agent Commission'
                    )}
                    label="Buyers Agent Commission"
                    onValueChange={v => {
                      field.onChange(v.value);
                      calculateTotalCommission();
                    }}
                    value={field.value || ''}
                  />
                )}
              />

              <Controller
                name="sellerCommission"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    customInput={CustomTextField}
                    {...inputProps}
                    decimalSeparator="."
                    allowNegative={false}
                    fullWidth
                    error={!!errors.sellerCommission?.message}
                    helperText={errors.sellerCommission?.message?.replace(
                      '"sellerCommission"',
                      'Sellers Agent Commission'
                    )}
                    label="Sellers Agent Commission"
                    onValueChange={v => {
                      field.onChange(v.value);
                      calculateTotalCommission();
                    }}
                    value={field.value || ''}
                  />
                )}
              />

              <Controller
                name="totalCommission"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    customInput={CustomTextField}
                    {...inputProps}
                    decimalSeparator="."
                    fullWidth
                    label="Total Commission"
                    disabled
                    value={field.value || ''}
                    error={!!errors.totalCommission?.message}
                    helperText={errors.totalCommission?.message.replace('"totalCommission"', 'Total Commission')}
                  />
                )}
              />
            </ContainerInputs>

            <CustomTextField
              select
              fullWidth
              defaultValue={3}
              name={'rate'}
              label="Contract Terms"
              variant="outlined"
              inputProps={register('terms')}
              error={!!errors.terms?.message}
              helperText={errors.terms?.message}>
              {[3, 6, 9, 12].map(el => (
                <MenuItem key={el} value={el}>
                  {el} months
                </MenuItem>
              ))}
            </CustomTextField>
          </ContentContainer>

          <FormControlLabel
            {...register('acceptRules')}
            control={<CustomCheckBox color={'error'} checked={watch('acceptRules')} />}
            label={<CheckBoxLabel>{textCheckBoxLabel()}</CheckBoxLabel>}
          />

          <ButtonsContainer>
            <CustomButton onClick={closeFun} variant="outlined">
              Cancel
            </CustomButton>

            <CustomButton type="submit" disabled={!watch('acceptRules') || !isValid} variant="contained" color="error">
              Submit Offer
            </CustomButton>
          </ButtonsContainer>
        </form>
      </MainContainer>
    </CustomModal>
  );
};

export default ModalSubmitOffer;
