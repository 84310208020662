import { Button, TextField } from '@mui/material';
import styled from 'styled-components';

export const MapsContainer = styled.div`
  width: 100%;
`;

export const MainMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 70%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderTitle = styled.h1`
  margin-top: 40px;
  font: ${props => props.theme.typography.h1.font};
`;

export const HeaderText = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  font: ${props => props.theme.typography.txt.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 32px;
  }
`;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: center;
    flex-direction: row;
    display: flex;
    height: 100px;
    justify-content: space-between;
  }
`;

export const TextFieldStyled = styled(TextField)`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 400px !important;
  }
`;

export const WatchButton = styled(Button)`
  margin-bottom: 16px !important;
  margin-top: 16px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursorr: pointer;
  width: 100%;
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 0;
    margin-top: 0;
    width: 183px;
  }
`;

export const CurcularContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
