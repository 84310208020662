import React, { useState } from 'react';
import { MainContainer, StyledButton, MessageBlock } from './styles';
import authService from '../../services/auth.service';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const SendEmailVerificationPage = () => {
  const { accessToken } = useSelector(state => state.auth);
  const [isSent, setIsSent] = useState(false);

  const sendEmail = async () => {
    setIsSent(true);
    authService
      .sendEmailVerification(accessToken)
      .then(() => {
        toast.success('An email has been sent');
      })
      .catch(e => {
        const message = e?.response?.data?.message;
        toast.error(message);
        setIsSent(false);
      });
  };

  return (
    <MainContainer>
      <MessageBlock>
        To use the service, please confirm your account by sending a verification link to your email address
      </MessageBlock>
      <StyledButton disabled={isSent} onClick={sendEmail} variant="contained" color="error">
        Send email
      </StyledButton>
    </MainContainer>
  );
};

export default SendEmailVerificationPage;
