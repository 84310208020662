import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { APP_ROUTES, TOAST_MESSAGES } from '../../utils/constants';
import AuthService from '../../services/auth.service';
import { MainContainer, StyledButton, ErrorMessageBlock } from './styles';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';

const VerifyPage = () => {
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('verification_token');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    verifyToken();
  }, [searchParams]);

  const redirectToLogin = () => {
    navigate(APP_ROUTES.LOGIN);
  };

  const verifyToken = () => {
    if (!token) return;
    AuthService.checkEmailVerification(token)
      .then(() => {
        toast.success(TOAST_MESSAGES.SUCCESS_EMAIL);
        dispatch(logout());
        redirectToLogin();
      })
      .catch(error => {
        const message = error?.response?.data?.message;
        setError(message);
      });
  };

  const reConfirmEmail = () => {
    AuthService.reConfirmEmailVerification(token)
      .then(() => {
        toast.success(TOAST_MESSAGES.SUCCESS_RE_SEND_EMAIL);
        dispatch(logout());
        redirectToLogin();
      })
      .catch(error => {
        const message = error?.response?.data?.message;
        setError(message);
      });
  };

  if (!error) return null;

  return (
    <MainContainer>
      <ErrorMessageBlock>{error}</ErrorMessageBlock>
      <StyledButton onClick={reConfirmEmail} variant="contained" color="error">
        re-send email
      </StyledButton>
    </MainContainer>
  );
};

export default VerifyPage;
