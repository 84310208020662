import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import { ReactComponent as Close } from '../../../../icons/close.svg';
import { ReactComponent as DoneAll } from '../../../../icons/done_all.svg';
import Checkbox from '@mui/material/Checkbox';

export const MainContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 764px;
  width: calc(100% - 40px);
  background-color: white;
  border-radius: 12px;
  padding: 32px;
`;

export const CustomModal = styled(Modal)`
  background: rgba(30, 34, 42, 0.32);
  backdrop-filter: blur(2px);
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const RedText = styled.span`
  color: #d32f2f;
`;

export const OfferDetailsTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const OfferDetailsText = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

export const OfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 14px;
`;

export const BrokerBio = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15px;
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const CloseButton = styled.div`
  transform: translateX(15px);
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px 24px;
  margin-top: 18px;
`;

export const CustomButton = styled(Button)`
  flex-grow: 1;
  &.MuiButton-root {
    min-width: 200px;
    padding: 9px 32px;
    border-radius: 12px;
    font-size: 20px;
    text-transform: capitalize;
    &:nth-child(1) {
      color: #2c2c2e;
      border: 1px solid #2c2c2e;
    }
  }
`;

export const BrokerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

export const BrokerFIO = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: 0.15px;
`;

export const BrokerCity = styled.div`
  font-size: 23px;
  font-weight: 400;
  font-style: normal;
`;

export const BrokerImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 120px;
`;

export const BrokerNotFoundImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 120px;
  border: 1px solid grey;
`;

export const CustomCheckBox = styled(Checkbox)`
  &.MuiButtonBase-root {
    margin-left: 6px;
  }
`;

export const CheckBoxLabel = styled.span`
  width: 100%;
  font-size: 13px;
`;

export const ServicesContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const DoneAllIcon = styled(DoneAll)`
  width: 21px;
  height: 21px;
`;

export const ServiceCard = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
  font-size: 12px;
`;

export const AvailableContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const AvailableServicesText = styled.p`
  font-size: 19px;
  font-weight: 700;
`;

export const AdditionalText = styled.div`
  font-weight: 100;
  font-size: 11px;
  line-height: 20px;
`;

export const CommissionsDetails = styled.span`
  margin-left: 10px;
`;
