import React from 'react';
import {
  ButtonsContainer,
  CloseIcon,
  CustomButton,
  CustomModal,
  HeadContainer,
  HeadTitle,
  MainContainer,
} from './styles';

const ModalRetractOffer = ({ open, closeFun, submit }) => {
  return (
    <CustomModal
      open={open}
      onClose={closeFun}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <MainContainer>
        <HeadContainer>
          <HeadTitle>Retract offer</HeadTitle>
          <div onClick={closeFun}>
            <CloseIcon />
          </div>
        </HeadContainer>
        <div>If you retract the Offer can’t be restored</div>
        <ButtonsContainer>
          <CustomButton onClick={closeFun} variant="outlined">
            Cancel
          </CustomButton>
          <CustomButton onClick={submit} variant="contained" color="error">
            Retract
          </CustomButton>
        </ButtonsContainer>
      </MainContainer>
    </CustomModal>
  );
};

export default ModalRetractOffer;
