import { Button, Pagination } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as Empty } from '../../icons/empty.svg';
import { ReactComponent as Plus } from '../../icons/plus.svg';

export const PageContainer = styled.div``;

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  margin-top: 40px;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const EmptyPropertiesContainer = styled.div``;
export const HeaderText = styled.h1`
  font: ${props => props.theme.typography.h1_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${props => props.theme.typography.h1.font};
  }
`;
export const CreateText = styled.p`
  font: ${props => props.theme.typography.txt.font};
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const CreateButton = styled(Button)`
  width: 100%;
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    margin-top: 24px;
    box-shadow: ${props => props.theme.shadow.buttonShadow};
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &.MuiButton-root {
      margin-top: 0;
      width: 272px;
    }
  }
`;

export const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
`;
export const PropertiesContainer = styled.div`
  width: 100%;
`;
export const PropertyContainer = styled.div`
  width: 100%;
`;
export const HeaderContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PropertyEmptyIcon = styled(Empty)`
  width: 100%;
  margin-top: 3%;
  height: 300px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-top: 5%;
    height: 405px;
  }
`;

export const PlusIcon = styled(Plus)`
  margin-right: 13px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const CustomPagination = styled(Pagination)`
  .MuiButtonBase-root {
    border-radius: 100px;
    font-size: 14px;
    &.Mui-selected {
      background-color: #b41117;
      color: white;
    }
  }
`;
