import { useEffect, useState } from 'react';
import { APP_ROUTES } from '../../utils/constants';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NotificationCard from '../../components/notificationCard';
import {
  EmptyPropertiesContainer,
  HeaderText,
  CreateContainer,
  PropertiesContainer,
  PageContainer,
  MainContainer,
  CreateText,
  HeaderContainer,
  PropertyContainer,
  CircleContainer,
  BackIcon,
  LinkStyled,
  PaginationContainer,
  CustomPagination,
} from './styles';
import { getAllNotifications } from '../../slices/notifications';

const COUNT_PAGE = 10;

export const NotificationsPage = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { loading, notifications, count } = useSelector(state => state.notifications);
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(getAllNotifications({ limit: COUNT_PAGE, offset: (page - 1) * COUNT_PAGE }));
  }, []);

  const changePage = value => {
    dispatch(getAllNotifications({ limit: COUNT_PAGE, offset: (value - 1) * COUNT_PAGE }));
    setPage(value);
  };

  return (
    <PageContainer>
      {!loading ? (
        <MainContainer>
          {!notifications?.length ? (
            <EmptyPropertiesContainer>
              <LinkStyled to={APP_ROUTES.MAIN}>
                <HeaderText>
                  <BackIcon />
                  Notifications
                </HeaderText>
              </LinkStyled>
              <CreateContainer>
                <CreateText>Notifications not found</CreateText>
              </CreateContainer>
            </EmptyPropertiesContainer>
          ) : (
            <PropertiesContainer>
              <HeaderContainer>
                <LinkStyled to={APP_ROUTES.MAIN}>
                  <HeaderText>
                    <BackIcon />
                    Notifications
                  </HeaderText>
                </LinkStyled>
              </HeaderContainer>
              <PropertyContainer>
                {notifications?.map(item => <NotificationCard key={item.id} item={item} user={user} />)}
              </PropertyContainer>
            </PropertiesContainer>
          )}
          {Boolean(notifications?.length) && (
            <PaginationContainer>
              <CustomPagination
                page={page}
                onChange={(e, value) => {
                  changePage(value);
                }}
                variant="outlined"
                shape="rounded"
                count={Math.ceil((count?.count_messages || 0) / COUNT_PAGE)}
                showFirstButton
                showLastButton
              />
            </PaginationContainer>
          )}
        </MainContainer>
      ) : (
        <CircleContainer>
          <CircularProgress style={{ color: '#C12026', marginTop: 300 }} />
        </CircleContainer>
      )}
    </PageContainer>
  );
};
