import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import PropertyCard from '../../components/propertyCard';
import {
  EmptyPropertiesContainer,
  HeaderText,
  CreateContainer,
  PropertiesContainer,
  PropertyEmptyIcon,
  PageContainer,
  MainContainer,
  CreateText,
  CreateButton,
  PlusIcon,
  HeaderContainer,
  PropertyContainer,
  PaginationContainer,
  CustomPagination,
} from './styles';
import { getAllProperties, setProperties } from '../../slices/property';
import { getAllPropertiesByFilter } from '../../slices/property';
import Loader from '../../components/loader';
import { getMetaDataAccount, setMeta } from '../../slices/account';

const COUNT_PAGE = 10;

export const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { loading, properties } = useSelector(state => state.properties);
  const { user } = useSelector(state => state.auth);
  const { meta, updateMeta, loading: loadingMeta } = useSelector(state => state.account);

  useEffect(() => {
    if (user.role.code === 'homeowner') {
      dispatch(getAllProperties());
    }
  }, []);

  useEffect(() => {
    if (!updateMeta && user.role.code !== 'homeowner') {
      dispatch(getMetaDataAccount());
    }
    return () => {
      dispatch(setMeta(null));
      dispatch(setProperties(null));
    };
  }, [updateMeta]);

  useEffect(() => {
    if (!loadingMeta && !updateMeta && meta) {
      if (Object.keys(meta).length) {
        const radiusLocation = `${meta.srid},${meta.latitude},${meta.longitude},${meta.distance}`;
        dispatch(getAllPropertiesByFilter(radiusLocation));
      } else {
        dispatch(getAllProperties());
      }
    }
  }, [updateMeta, loadingMeta, meta]);

  const propertiesMemo = useMemo(() => {
    if (!properties) return [];
    return properties.slice((page - 1) * COUNT_PAGE, page * COUNT_PAGE);
  }, [properties, page]);

  return (
    <PageContainer>
      {!loading && properties ? (
        <MainContainer>
          {!properties?.length ? (
            <EmptyPropertiesContainer>
              <HeaderText>Properties</HeaderText>
              {user?.role?.name !== 'Broker' ? (
                <CreateContainer>
                  <PropertyEmptyIcon />
                  <CreateText>You have not yet registered any property objects</CreateText>
                  <CreateButton onClick={() => navigate(APP_ROUTES.CREATE_PROPERTY)}>
                    <PlusIcon />
                    Create property
                  </CreateButton>
                </CreateContainer>
              ) : (
                <CreateContainer>
                  <PropertyEmptyIcon />
                  <CreateText>There are no properties in your area yet.</CreateText>
                  <CreateButton onClick={() => navigate(APP_ROUTES.LOCATION)}>Edit your target location</CreateButton>
                </CreateContainer>
              )}
            </EmptyPropertiesContainer>
          ) : (
            <PropertiesContainer>
              <HeaderContainer>
                <HeaderText>Properties</HeaderText>
                {user?.role?.name !== 'Broker' && (
                  <CreateButton onClick={() => navigate(APP_ROUTES.CREATE_PROPERTY)}>
                    <PlusIcon />
                    Create property
                  </CreateButton>
                )}
              </HeaderContainer>
              <PropertyContainer>
                {propertiesMemo.map(item => (
                  <PropertyCard key={item.id} property={item} isBroker={user?.role?.name === 'Broker'} />
                ))}
              </PropertyContainer>
              <PaginationContainer>
                <CustomPagination
                  page={page}
                  onChange={(e, value) => setPage(value)}
                  variant="outlined"
                  shape="rounded"
                  count={Math.ceil(properties.length / COUNT_PAGE)}
                  showFirstButton
                  showLastButton
                />
              </PaginationContainer>
            </PropertiesContainer>
          )}
        </MainContainer>
      ) : (
        <Loader />
      )}
    </PageContainer>
  );
};
