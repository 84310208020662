import styled from 'styled-components';
import { Button, Pagination } from '@mui/material';
import TextField from '@mui/material/TextField';

export const OffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 16px;
  }
`;

export const OfferCard = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  padding: 24px 32px;
  min-height: 286px;
  box-shadow:
    0px 0px 2px -2px rgba(41, 45, 79, 0.2),
    0px 0px 14px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    min-height: auto;
  }
`;

export const PropertyAddress = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  letter-spacing: 0.15px;
  margin-bottom: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 28px;
  }
`;

export const PropertyCost = styled.div`
  font-size: 26px;
  font-weight: 500;
  line-height: 31.2px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 24px;
  }
`;

export const PropertyStatus = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 31.2px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 20px;
  }
`;

export const CustomButton = styled(Button)`
  flex-grow: 1;
  &.MuiButton-root {
    padding: 9px 32px;
    border-radius: 12px;
    font-size: 20px;
    text-transform: capitalize;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const CustomPagination = styled(Pagination)`
  .MuiButtonBase-root {
    border-radius: 100px;
    font-size: 14px;
    &.Mui-selected {
      background-color: #b41117;
      color: white;
    }
  }
`;

export const Select = styled(TextField)`
  .MuiInputBase-root {
    width: 250px;
    margin-bottom: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: auto;
    }
  }
`;
