import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  position: relative;
`;
export const BrokerContainer = styled.div`
  position: absolute;
  left: -50px;
  width: 473px;
  padding: 18px 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow:
    0px 0px 4px -2px rgba(7, 13, 63, 0.36),
    0px 0px 14px 0px rgba(5, 13, 54, 0.12);
  z-index: 1;
`;

export const BrokerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BrokerImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 120px;
`;

export const BrokerNotFoundImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 120px;
  border: 1px solid grey;
`;

export const ServicesContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px 16px;
`;

export const Service = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
