import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import profileReducer from '../slices/profile';
import propertiesReducer from '../slices/property';
import offersReducer from '../slices/offer';
import notificationsReducer from '../slices/notifications';
import paymentReducer from '../slices/payment';
import accountReducer from '../slices/account';

const reducer = {
  auth: authReducer,
  profile: profileReducer,
  properties: propertiesReducer,
  offers: offersReducer,
  notifications: notificationsReducer,
  payment: paymentReducer,
  account: accountReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
