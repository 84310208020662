import React, { memo } from 'react';
import { RedSlider, BoxStyled } from './styles';

const valuetext = value => {
  return value;
};

const marks = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
];

export const DiscreteSlider = memo(props => {
  const handleSliderChange = (event, newValue) => {
    props.onValueTextChange(valuetext(newValue));
  };

  return (
    <BoxStyled>
      <RedSlider
        label="Radius"
        aria-label="Radius"
        defaultValue={5}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={5}
        marks={marks}
        min={5}
        max={100}
        onChange={handleSliderChange}
      />
    </BoxStyled>
  );
});
