import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PaymentService from '../services/payment.service';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';

export const getPaymentMethods = createAsyncThunk('payment/get-payment-methods', async (data, thunkAPI) => {
  try {
    const response = await PaymentService.getListPaymentMethods();

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const choosePreferredPaymentMethod = createAsyncThunk(
  'payment/choose-preferred-payment-method',
  async (data, thunkAPI) => {
    try {
      const response = await PaymentService.choosePreferredPaymentMethod(data);
      thunkAPI.dispatch(getPaymentMethods());

      return response.data;
    } catch (error) {
      const message = error?.response?.data?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const paymentMethodDetach = createAsyncThunk('payment/method-detach', async (data, thunkAPI) => {
  try {
    const response = await PaymentService.paymentMethodDetach(data);
    thunkAPI.dispatch(getPaymentMethods());

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const createPaymentMethod = createAsyncThunk('payment/crate-payment-method', async (data, thunkAPI) => {
  try {
    const response = await PaymentService.cratePaymentMethod();
    window.location.href = response.data.url;

    // return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const createSubscribe = createAsyncThunk('payment/create-subscribe', async (data, thunkAPI) => {
  try {
    const response = await PaymentService.createSubscribe();
    window.location.href = response.data.url;

    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  loading: false,
  payments: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentMethods.pending, state => {
        state.loading = true;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_PAYMENT);
      })

      .addCase(choosePreferredPaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(choosePreferredPaymentMethod.pending, state => {
        state.loading = true;
      })
      .addCase(choosePreferredPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_PAYMENT);
      })

      .addCase(paymentMethodDetach.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(paymentMethodDetach.pending, state => {
        state.loading = true;
      })
      .addCase(paymentMethodDetach.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_PAYMENT);
      })

      .addCase(createPaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createPaymentMethod.pending, state => {
        state.loading = true;
      })
      .addCase(createPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_PAYMENT);
      })

      .addCase(createSubscribe.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createSubscribe.pending, state => {
        state.loading = true;
      })
      .addCase(createSubscribe.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_PAYMENT);
      });
  },
});
export default paymentSlice.reducer;
