import axios from '../utils/axios/index';
import { API_ROUTES, APP_ROUTES } from '../utils/constants';

const frontUrl = window.location.href;

const getListPaymentMethods = () => {
  return axios.get(API_ROUTES.PAYMENTS + '/payment_method_index');
};
const choosePreferredPaymentMethod = id => {
  return axios.post(`${API_ROUTES.PAYMENTS}/payment_method_prefer/${id}`);
};

const paymentMethodDetach = id => {
  return axios.post(`${API_ROUTES.PAYMENTS}/payment_method_detach/${id}`);
};

const cratePaymentMethod = () => {
  return axios.post(API_ROUTES.PAYMENTS + '/payment_method_setup', {
    success_url: frontUrl + '?success=true',
    cancel_url: frontUrl + '?success=false',
  });
};

const createSubscribe = () => {
  const url = window.location.origin + APP_ROUTES.MAIN;
  return axios.post(API_ROUTES.PAYMENTS + '/broker_account_subscribe', {
    success_url: url + '?success_subscribe=true',
    cancel_url: url + '?success_subscribe=false',
  });
};

const PaymentService = {
  getListPaymentMethods,
  choosePreferredPaymentMethod,
  paymentMethodDetach,
  cratePaymentMethod,
  createSubscribe,
};
export default PaymentService;
