import styled from 'styled-components';
import { Button, DialogTitle, TextField } from '@mui/material';
import { ReactComponent as Close } from '../../icons/close.svg';

export const DialogTitleStyled = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 16px 12px 32px 12px;
    width: 70%;
    font: ${props => props.theme.typography.h3_mobile.font};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &.MuiDialogTitle-root {
      padding: 32px;
      font: ${props => props.theme.typography.h2.font};
    }
  }
`;

export const DialogContentStyled = styled.div`
  padding: 0 12px 32px 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 32px 32px 32px;
  }
`;

export const ListStyled = styled.ul`
  list-style-position: inside;
  font: ${props => props.theme.typography.txt.font};
`;

export const TextForFieldsBlock = styled.span`
  font: ${props => props.theme.typography.txt.font};
  color: ${props => props.theme.palette.simpleGray};
  display: block;
  padding-top: 8px;
`;

export const CloseIcon = styled(Close)`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 17px;
  right: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 38px;
    right: 32px;
  }
`;

export const FormStyled = styled.form`
  margin-top: 32px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding-bottom: 0;
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TextFieldStyled = styled(TextField)`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 50%;
  }
`;

export const LabelForFieldsBlock = styled.h4`
  font: ${props => props.theme.typography.h4.font};
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  padding: 0px 12px 16px 12px;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 32px 32px 32px;
    flex-direction: row;
    gap: 14px;
  }
`;

export const CreateButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    box-shadow: ${props => props.theme.shadow.buttonShadow};
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    pointer-events: none;
  }
`;

export const DeleteButton = styled(Button)`
  width: 100%;
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 12px;
    box-shadow: ${props => props.theme.shadow.buttonShadow};
    border: 1px solid ${props => props.theme.palette.secondary};
    border-color: ${props => props.theme.palette.secondary};
    color: ${props => props.theme.palette.secondary};
    font: ${props => props.theme.typography.button_secondary.font};
  }
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.simpleGray};
    border: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;
