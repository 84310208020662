import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { APP_ROUTES, rulesURL } from '../../utils/constants';
import { FormControlLabel, CircularProgress } from '@mui/material';
import {
  CheckBoxStyled,
  CheckBoxLabel,
  MainContainer,
  CreatePropertyLabel,
  PropertyInformation,
  PropertyInfo,
  CheckBoxsContainer,
  FormStyled,
  PageContainer,
  CreateButton,
  BackIcon,
  LinkStyled,
  CustomTextField,
  FieldsContainer,
  PropertyContainer,
} from './styles';
import { joiResolver } from '@hookform/resolvers/joi';
import { schemaCreateProperty } from '../../joi/index';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createProperty } from '../../slices/property';
import { PropertyDialog } from '../../components/propertyContactDialog';
import Loader from '../../components/loader';
import MenuItem from '@mui/material/MenuItem';

const CreatePropertyComponent = () => {
  const [libraries] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_BASE_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    price: 0,
    resolver: joiResolver(schemaCreateProperty),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [place, setPlace] = useState(false);
  const [touched, setTouched] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { loading } = useSelector(state => state.properties);
  const { isLoggedIn, user } = useSelector(state => state.auth);

  const handleSubmitDialog = contactData => {
    const firstForm = getValues();
    if (marker) {
      firstForm.location = `4326,${marker.lat},${marker.lng}`;
      firstForm.email = user.email;
      const property = {
        address: firstForm.address,
        location: firstForm.location,
        cost: Number(firstForm.price),
        owner: firstForm.email,
        contact_first_name: contactData.firstName,
        contact_last_name: contactData.lastName,
        contact_email: contactData.email,
        contact_phone: contactData.phone,
        bedrooms: firstForm.bedrooms,
        bathrooms: firstForm.bathrooms,
        sale_period: firstForm.sale_period,
        sqrt: firstForm.sqrt,
        built_in: firstForm.built_in,
      };
      dispatch(createProperty(property)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          setOpenDialog(false);
          navigate(APP_ROUTES.MAIN);
        }
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onPlaceChanged = () => {
    const place = inputRef.current.getPlace();
    if (!place || !place.geometry || !place.geometry.location) {
      setAddressError(true);
      setPlace(false);
      return;
    }
    if (place || place.geometry.viewport || place.geometry.location) {
      setMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setPlace(true);
    }
  };
  const onPlaceChangedFromCard = () => {
    if (marker) {
      const place = {
        geometry: {
          location: new window.google.maps.LatLng(marker.lat, marker.lng),
        },
      };
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: place.geometry.location }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const fullAddress = results[0].formatted_address;
            setValue('address', fullAddress);
            setAddressError(false);
            setPlace(true);
          } else {
            setPlace(false);
            setAddressError(true);
          }
        } else {
          toast.error('Error: ' + status);
        }
      });
    }
  };

  const helperTextSet = () => {
    if (touched && address === '') {
      return 'Address is required';
    }
    if (addressError) {
      return 'Address not found';
    }
    if (touched && !place) {
      return 'Address not found';
    }
  };

  const onSubmit = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (!isLoggedIn && !user) {
      navigate(APP_ROUTES.LOGIN);
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    onPlaceChangedFromCard();
  }, [marker]);

  return (
    <PageContainer>
      {isLoaded ? (
        <MainContainer>
          <CreatePropertyLabel>
            <LinkStyled to={APP_ROUTES.MAIN}>
              <BackIcon />
            </LinkStyled>
            Create property
          </CreatePropertyLabel>
          <PropertyInformation>
            <PropertyInfo>Property data</PropertyInfo>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <PropertyContainer>
                <FieldsContainer>
                  <Controller
                    name="address"
                    control={control}
                    render={() => (
                      <Autocomplete
                        onLoad={autocomplete => {
                          inputRef.current = autocomplete;
                        }}
                        onPlaceChanged={onPlaceChanged}>
                        <CustomTextField
                          fullWidth
                          error={(touched && address === '') || (touched && !place) || addressError}
                          helperText={helperTextSet()}
                          onBlur={() => {
                            setTouched(true);
                          }}
                          placeholder="Address"
                          label="Address"
                          onChange={e => {
                            setAddressError(false);
                            setPlace(false);
                            setAddress(e.target.value);
                          }}
                        />
                      </Autocomplete>
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        customInput={CustomTextField}
                        thousandSeparator={true}
                        error={errors.price?.message ? true : false}
                        helperText={errors.price?.message}
                        prefix={'$ '}
                        label="What do you think your property is worth?"
                        placeholder="What do you think your property is worth?"
                        onValueChange={v => {
                          // value without dollar sign
                          field.onChange(v.value);
                        }}
                        value={field.value || ''}
                      />
                    )}
                  />
                  <CustomTextField
                    {...register('bedrooms')}
                    fullWidth
                    placeholder="Bedroom"
                    label="Bedroom"
                    error={errors.bedrooms?.message ? true : false}
                    helperText={errors.bedrooms?.message}
                  />
                  <CustomTextField
                    select
                    fullWidth
                    defaultValue={'4 weeks'}
                    name={'sale_period'}
                    label="When are you prepared to list on the market?"
                    variant="outlined"
                    inputProps={register('sale_period')}
                    error={errors.sale_period}
                    helperText={errors.sale_period?.message}>
                    {['Immediately', '4 weeks', '8 weeks', '12 weeks', 'Not sure yet'].map(el => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FieldsContainer>

                <FieldsContainer>
                  <CustomTextField
                    fullWidth
                    {...register('bathrooms')}
                    placeholder="Bathroom"
                    label="Bathroom"
                    error={errors.bathrooms?.message ? true : false}
                    helperText={errors.bathrooms?.message}
                  />
                  <CustomTextField
                    fullWidth
                    {...register('built_in')}
                    placeholder="What year was your home built?"
                    label="What year was your home built?"
                    error={errors.built_in?.message ? true : false}
                    helperText={errors.built_in?.message}
                  />
                  <Controller
                    name="sqrt"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        customInput={CustomTextField}
                        fullWidth
                        decimalScale={3}
                        suffix={' sqft'}
                        decimalSeparator="."
                        error={errors.sqrt?.message ? true : false}
                        helperText={errors.sqrt?.message}
                        label="Property sqft"
                        onValueChange={v => {
                          // value without dollar sign
                          field.onChange(v.value);
                        }}
                        value={field.value || ''}
                      />
                    )}
                  />
                </FieldsContainer>
              </PropertyContainer>

              <CheckBoxsContainer>
                <FormControlLabel
                  control={<CheckBoxStyled />}
                  {...register('rules')}
                  label={
                    <CheckBoxLabel>
                      If you click the "Create property" button, you agree to our{' '}
                      <Link style={{ color: '#C12026' }} to={rulesURL}>
                        rules
                      </Link>
                      .
                    </CheckBoxLabel>
                  }
                />
                <FormControlLabel
                  control={<CheckBoxStyled />}
                  {...register('property')}
                  label={
                    <CheckBoxLabel>
                      You have the right to sell this property and you are not currently under listing agreement with an
                      agent or broker.
                    </CheckBoxLabel>
                  }
                />
              </CheckBoxsContainer>
              <CreateButton
                disabled={!isValid || !marker || !place || addressError || loading}
                type="submit"
                variant="contained">
                {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Create property'}
              </CreateButton>
            </FormStyled>
          </PropertyInformation>
        </MainContainer>
      ) : (
        <Loader />
      )}
      {openDialog && (
        <PropertyDialog status={openDialog} handleSubmitForm={handleSubmitDialog} handleClose={handleCloseDialog} />
      )}
    </PageContainer>
  );
};

export default CreatePropertyComponent;
