import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { ReactComponent as KeyboardBackSpace } from '../../../icons/keyboard-backspace.svg';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const MainContainer = styled.div`
  max-width: 679px;
  margin: 40px auto;
  padding: 0 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 24px auto;
  }
`;

export const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const CustomTextField = styled(TextField)`
  min-width: 282px !important;
  //& :disabled {
  //  color: rgba(0, 0, 0, 0.87) !important;
  //  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  //}
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: 32px 64px;
  flex-wrap: wrap;
`;

export const TitleContainer = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.2px;
  letter-spacing: 0.15px;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 24px;
    font-size: 24px;
  }
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  width: max-content;
  text-decoration: none;
  color: black;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 52.8px;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 36px;
  }
`;

export const KeyboardBackSpaceIcon = styled(KeyboardBackSpace)`
  margin-right: 8px;
`;

export const RedButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 12px;
    text-transform: none;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 30px;
    margin-top: 32px;
  }
`;
