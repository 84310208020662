import Joi from 'joi';
import { regexPhonePattern } from '../utils/constants/index';

export const schemaFormSignUp = Joi.object({
  role: Joi.string().required(),
  firstName: Joi.string().min(2).max(80).required(),
  lastName: Joi.string().min(2).max(80).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({ 'any.only': 'invalid email field' }),
  phone: Joi.string().pattern(new RegExp(regexPhonePattern)).required().messages({
    'object.regex': 'Must have at least 8 characters',
    'string.pattern.base': '"phone" must be a valid phone',
  }),
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.string()
    .equal(Joi.ref('password'))
    .required()
    .label('Confirm password')
    .messages({ 'any.only': '{{#label}} does not match' }),
  howYouFindUs: Joi.string().min(2).max(200).required(),
  privacyPolicy: Joi.boolean().required().valid(true),
});

export const schemaLogin = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({ 'any.only': 'invalid email field' }),
  password: Joi.string().min(8).required(),
});

export const schemaFormFirstLogin = Joi.object({
  licenseState: Joi.string().required().max(32),
  licenseNumber: Joi.string().required().max(12),
  companyName: Joi.string().required().max(50),
  companyStreet: Joi.string().required().max(100),
  city: Joi.string().required().max(100),
  state: Joi.string().required().max(100),
  zipCode: Joi.string().required().max(100),
  website: Joi.string().required().max(200),
  experience: Joi.number().required().max(100).min(0),
  bio: Joi.string().required().max(700),
  sold: Joi.number().integer().min(0),
  socialMedia: Joi.boolean().required(),
  mailers: Joi.boolean().required(),
  openHouses: Joi.boolean().required(),
  listingPhoto: Joi.boolean().required(),
  listingVideo: Joi.boolean().required(),
  drone: Joi.boolean().required(),
  furniture: Joi.boolean().required(),
});

export const schemaCreateProperty = Joi.object({
  address: Joi.string().required().max(300),
  price: Joi.string().required().min(1),
  sqrt: Joi.number().max(9999999).required(),
  bedrooms: Joi.number().max(100).required(),
  bathrooms: Joi.number().max(100).required(),
  built_in: Joi.number().max(2024).required(),
  sale_period: Joi.string().required(),
  rules: Joi.boolean().required().valid(true),
  property: Joi.boolean().required().valid(true),
});

export const schemaCreatePropertyContacts = Joi.object({
  firstName: Joi.string().min(2).required(),
  lastName: Joi.string().min(2).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({ 'any.only': 'invalid email field' }),
  phone: Joi.string().pattern(new RegExp(regexPhonePattern)).required().messages({
    'object.regex': 'Must have at least 8 characters',
    'string.pattern.base': '"phone" must be a valid phone',
  }),
});

export const schemaSubmitOffer = Joi.object({
  buyersCommission: Joi.when('typeCommission', {
    is: 'percent',
    then: Joi.number().min(1).max(100).messages({
      'number.min': '{{#label}} must be greater than 0',
    }),
    otherwise: Joi.number().min(1).messages({
      'number.min': '{{#label}} must be greater than 0',
    }),
  }),
  sellerCommission: Joi.when('typeCommission', {
    is: 'percent',
    then: Joi.number().min(1).max(100).messages({
      'number.min': '{{#label}} must be greater than 0',
    }),
    otherwise: Joi.number().min(1).messages({
      'number.min': '{{#label}} must be greater than 0',
    }),
  }),
  totalCommission: Joi.when('typeCommission', {
    is: 'percent',
    then: Joi.number().min(1).max(100),
    otherwise: Joi.number(),
  }),
  terms: Joi.number().min(3).required(),
  typeCommission: Joi.string(),
  acceptRules: Joi.boolean(),
});

export const schemaAccessRecovery = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({ 'any.only': 'invalid email field' }),
});

export const schemaNewPassword = Joi.object({
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.string()
    .equal(Joi.ref('password'))
    .required()
    .label('Confirm password')
    .messages({ 'any.only': '{{#label}} does not match' }),
});

export const schemaUpdateProfile = Joi.object({
  first_name: Joi.string().min(2).max(80).required(),
  last_name: Joi.string().min(2).max(80).required(),
  phone: Joi.string().pattern(new RegExp(regexPhonePattern)).required().messages({
    'object.regex': 'Must have at least 8 characters',
    'string.pattern.base': '"phone" must be a valid phone',
  }),
});
