import axios from '../utils/axios/index';
import { API_ROUTES } from '../utils/constants';

const getOffers = () => {
  return axios.get(API_ROUTES.OFFER);
};

const getOfferById = id => {
  return axios.get(API_ROUTES.OFFER + `/${id}`);
};

const acceptOffer = offerId => {
  return axios.patch(API_ROUTES.OFFER + `/${offerId}`, { status: 'accepted' });
};

const changeOfferStatus = (offerId, status) => {
  return axios.patch(API_ROUTES.OFFER + `/${offerId}`, { status: status });
};

const deleteOffer = offerId => {
  return axios.delete(API_ROUTES.OFFER + `/${offerId}`);
};

const createOffer = data => {
  return axios.post(API_ROUTES.OFFER, data);
};

const OfferService = {
  getOffers,
  getOfferById,
  acceptOffer,
  deleteOffer,
  createOffer,
  changeOfferStatus,
};
export default OfferService;
