import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ReactComponent as Visa } from '../../icons/visa.svg';
import { ReactComponent as MasterCard } from '../../icons/master-card.svg';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 12px;
  gap: 16px;
  box-shadow:
    0px 0px 2px -2px rgba(41, 45, 79, 0.2),
    0px 0px 14px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 8px;
  }
`;

export const CardCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    margin-left: auto;
    padding: 0 !important;
  }
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const CheckIcon = styled(CheckCircleIcon)`
  color: ${props => props.theme.palette.redMain};
`;

export const UnCheckedIcon = styled(RadioButtonUncheckedIcon)`
  color: ${props => props.theme.palette.redMain};
`;

export const VisaIcon = styled(Visa)`
  border-radius: 6px;
  width: 60px;
  height: 50px;
`;

export const MasterCardIcon = styled(MasterCard)`
  border-radius: 6px;
  width: 60px;
  height: 50px;
`;
