import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../services/auth.service';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../utils/constants';

const accessToken = JSON.parse(localStorage.getItem('accessToken'))
  ? JSON.parse(localStorage.getItem('accessToken'))
  : '';

const emailForForgot = JSON.parse(localStorage.getItem('emailForForgot'))
  ? JSON.parse(localStorage.getItem('emailForForgot'))
  : '';
const emailConfirmed = localStorage.getItem('email-confirmed') === 'true';
const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null;
const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))
  ? JSON.parse(localStorage.getItem('isLoggedIn'))
  : false;

export const signUp = createAsyncThunk(
  'auth/sign-up',
  async ({ firstName, lastName, email, phone, password, role, howYouFindUs }, thunkAPI) => {
    try {
      const response = await AuthService.register(firstName, lastName, email, phone, password, role, howYouFindUs);
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAccessToken = createAsyncThunk(
  'auth/create-access-token',
  async ({ email, password, loginStatus }, thunkAPI) => {
    try {
      const response = await AuthService.createAccessToken(email, password);
      return { data: response.data, loginStatus };
    } catch (error) {
      const message = error?.response?.data?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendEmailVerification = createAsyncThunk('auth/send-email-verification', async (token, thunkAPI) => {
  if (!token) throw new Error('The token does not exist');
  try {
    const response = await AuthService.sendEmailVerification(token);
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const checkPasswordRecovery = createAsyncThunk(
  'auth/check-password-recovery',
  async ({ verificationToken, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.checkPasswordRecovery(verificationToken, email, password);
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendPasswordRecovery = createAsyncThunk('auth/send-password-recovery', async (email, thunkAPI) => {
  try {
    const response = await AuthService.sendPasswordRecovery(email);
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const getUserInfo = createAsyncThunk('account/get_current', async (email, thunkAPI) => {
  try {
    const response = await AuthService.getUserInfo();
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateAccount = createAsyncThunk('account/update', async (data, thunkAPI) => {
  try {
    const response = await AuthService.updateAccount(data);
    thunkAPI.dispatch(getUserInfo());
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.message;
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isLoggedIn: isLoggedIn,
  emailConfirmed: emailConfirmed,
  loading: false,
  user: user,
  accessToken: accessToken,
  sendEmailStatus: false,
  emailForForgot,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.user = null;
      state.accessToken = '';
      state.isLoggedIn = false;
      state.emailConfirmed = false;
      localStorage.clear();
    },
    getToken: state => {
      return state.accessToken;
    },
    setEmailForForgotPassword: (state, action) => {
      state.emailForForgot = action.payload;
      localStorage.setItem('emailForForgot', JSON.stringify(action.payload));
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signUp.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(signUp.pending, state => {
        state.loading = true;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_SIGN_UP);
      })
      .addCase(createAccessToken.fulfilled, (state, action) => {
        localStorage.setItem('accessToken', JSON.stringify(action.payload.data.access_token));
        localStorage.setItem('user', JSON.stringify(action.payload.data.owner));
        localStorage.setItem('isLoggedIn', JSON.stringify(action.payload.loginStatus));
        localStorage.setItem('email-confirmed', JSON.stringify(action.payload.data.owner.is_verified));
        state.user = action.payload.data.owner;
        state.accessToken = action.payload.data.access_token;
        state.isLoggedIn = action.payload.loginStatus;
        state.loading = false;
        state.emailForForgot = '';
        state.emailConfirmed = action.payload.data.owner.is_verified;
        if (localStorage.getItem('emailForForgot')) localStorage.removeItem('emailForForgot');
      })
      .addCase(createAccessToken.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createAccessToken.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_LOGIN);
      })
      .addCase(sendPasswordRecovery.fulfilled, (state, action) => {
        state.loading = false;
        state.sendEmailStatus = true;
      })
      .addCase(sendPasswordRecovery.pending, state => {
        state.loading = true;
      })
      .addCase(sendPasswordRecovery.rejected, (state, action) => {
        state.loading = false;
        state.sendEmailStatus = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_EMAIL);
      })
      .addCase(checkPasswordRecovery.fulfilled, state => {
        state.loading = false;
        state.emailForForgot = '';
        if (localStorage.getItem('emailForForgot')) localStorage.removeItem('emailForForgot');
      })
      .addCase(checkPasswordRecovery.pending, state => {
        state.loading = true;
      })
      .addCase(checkPasswordRecovery.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload || TOAST_MESSAGES.ERROR_EMAIL);
      })

      .addCase(getUserInfo.fulfilled, (state, action) => {
        localStorage.setItem('user', JSON.stringify(action.payload));
        state.user = action.payload;
      })

      .addCase(updateAccount.fulfilled, () => {
        toast.success(TOAST_MESSAGES.SUCCESS);
      });
  },
});

export const { logout, getToken, setEmailForForgotPassword, setIsLoggedIn } = authSlice.actions;
export default authSlice.reducer;
