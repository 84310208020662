import React, { useEffect } from 'react';
import { FormContainer } from './styles';
import FormLogin from './loginForm';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import { useSelector } from 'react-redux';

const LoginPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector(state => state.auth);

  useEffect(() => {
    if (isLoggedIn && user?.role?.code === 'broker') {
      return navigate(APP_ROUTES.BROKER_FIRST_LOGIN);
    }
    if (isLoggedIn && user?.role?.code === 'homeowner') {
      return navigate(APP_ROUTES.MAIN);
    }
  }, [isLoggedIn]);

  return (
    <>
      {!isLoggedIn && (
        <FormContainer>
          <FormLogin />
        </FormContainer>
      )}
    </>
  );
};

export default LoginPage;
