import React from 'react';
import { MainContainer, Links, ContainerInfo, ContainerLinks } from './styles';
import { footerData } from './constants';

const Footer = () => {
  return (
    <MainContainer>
      <ContainerInfo>
        <div>Mailing Address: 4 Washington Street N Suite#4, Auburn, ME 04210</div>
        <div>Phone: (207) 405-0156</div>
      </ContainerInfo>
      <ContainerLinks>
        {footerData.map(({ title, link }) => (
          <Links key={title} href={link}>
            {title}
          </Links>
        ))}
      </ContainerLinks>
    </MainContainer>
  );
};

export default Footer;
