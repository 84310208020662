import styled from 'styled-components';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 20px;
  }
`;

export const CustomButton = styled(Button)`
  &.MuiButton-root {
    padding: 9px 32px;
    border-radius: 12px;
    font-size: 20px;
    text-transform: capitalize;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
    }
  }
`;
