import styled from 'styled-components';

export const MainContainer = styled.footer`
  padding: 82px 24px 32px 24px;
`;

export const ContainerInfo = styled.div`
  text-align: center;
  margin-bottom: 30px;
  color: #b2b4b2;
  font-size: 13px;
  line-height: 18px;
`;

export const ContainerLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px 32px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Links = styled.a`
  color: #131217;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 28.8px;
  letter-spacing: 0.15px;
`;
