import { memo } from 'react';
import {
  CardContainer,
  PropertyInfoBlock,
  Address,
  Price,
  CreateButton,
  ToIcon,
  DeleteButton,
  ButtonsGroup,
  Status,
  ContainerAddress,
  AdditionalInfoBlock,
  RedText,
  PropertyInfoContainer,
  Text,
  PropertyAdditionalInfo,
  Ellipse,
} from './styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllProperties, updateStatusPropertyById } from '../../slices/property';
import { APP_ROUTES } from '../../utils/constants';
import { formatNumber } from '../../utils/helpers';

const PropertyCard = memo(props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateStatusProperty = () => {
    if (props.property.id) {
      const status = props.property.status === 'published' ? 'draft' : 'published';
      dispatch(updateStatusPropertyById({ id: props.property.id, status: status })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(getAllProperties());
        }
      });
    }
  };

  const buttonText = () => {
    return !props.isBroker ? 'View offers' : 'More details';
  };

  const getColorText = () => {
    const status = props.property.status;
    if (status === 'published') return 'green';
    if (status === 'draft') return 'yellow';
    return 'red';
  };

  const propertyAdditionalInfo = () => {
    const { property } = props;
    return {
      Bathrooms: property.bathrooms || 0,
      Bedrooms: property.bedrooms || 0,
      sqft: property.sqrt || 0,
      'Built in': property.built_in || 0,
    };
  };

  const getNameStatus = () => {
    const status = props.property.status;
    if (status === 'draft') return 'Unpublished';
    return status;
  };

  return (
    <CardContainer>
      <PropertyInfoBlock>
        <PropertyInfoContainer>
          <div>
            <ContainerAddress>
              <Address>{props.property.address}</Address>
              {!props.isBroker && <Status className={getColorText()}>{getNameStatus()}</Status>}
            </ContainerAddress>
            <Price>Desired Price: {formatNumber(props.property.cost)}</Price>
          </div>
          {!props.isBroker ? (
            <AdditionalInfoBlock>
              <Text>
                Brokers: <RedText>{props.property?.offers?.length}</RedText>
              </Text>
            </AdditionalInfoBlock>
          ) : (
            <AdditionalInfoBlock className={'property-info'}>
              {Object.entries(propertyAdditionalInfo()).map(([key, value], index) => (
                <PropertyAdditionalInfo key={key}>
                  <Ellipse />
                  <div>{index !== 3 ? `${value} ${key}` : `${key} ${value}`}</div>
                </PropertyAdditionalInfo>
              ))}
            </AdditionalInfoBlock>
          )}
        </PropertyInfoContainer>
        <ButtonsGroup>
          <CreateButton onClick={() => navigate(APP_ROUTES.PROPERTY + `/${props.property.id}`)}>
            {buttonText()}
            {!props.isBroker && <ToIcon />}
          </CreateButton>
          {!props.isBroker && props.property.status !== 'ended' && (
            <DeleteButton onClick={updateStatusProperty}>
              {props.property.status === 'published' ? 'Unpublish' : 'Publish'}
            </DeleteButton>
          )}
        </ButtonsGroup>
      </PropertyInfoBlock>
    </CardContainer>
  );
});

export default PropertyCard;
