import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { joiResolver } from '@hookform/resolvers/joi';
import { APP_ROUTES } from '../../../utils/constants';
import {
  FormStyled,
  LogoIcon,
  FormLabel,
  FieldsContainer,
  HeadContainer,
  LoginButton,
  QuestionContainer,
  QuestionItemText,
} from './styles';
import { schemaLogin } from '../../../joi';
import { useDispatch, useSelector } from 'react-redux';
import { createAccessToken } from '../../../slices/auth';
import { CircularProgress } from '@mui/material';

function FormLogin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemaLogin),
  });

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auth);

  const onSubmit = data => {
    dispatch(createAccessToken({ email: data.email, password: data.password, loginStatus: true }));
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <LogoIcon />
      <HeadContainer>
        <FormLabel>Log In</FormLabel>
      </HeadContainer>
      <FieldsContainer>
        <TextField
          name="email"
          label="Email"
          placeholder="Email"
          {...register('email')}
          inputProps={{ maxLength: 120 }}
          error={errors.email?.message ? true : false}
          helperText={errors.email?.message}
        />
        <TextField
          type={showPassword ? 'text' : 'password'}
          name="password"
          label="Password"
          placeholder="Password"
          {...register('password')}
          inputProps={{ maxLength: 100 }}
          error={errors.password?.message ? true : false}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? (
                    <VisibilityOff style={{ fill: 'black' }} />
                  ) : (
                    <Visibility style={{ fill: 'black' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoginButton disabled={loading} type="submit" variant="contained">
          {loading ? <CircularProgress style={{ color: 'white' }} /> : 'Log in'}
        </LoginButton>
        <QuestionContainer>
          <QuestionItemText to={APP_ROUTES.ACCESS_RECOVERY}>Forgot password?</QuestionItemText>
          <QuestionItemText to={APP_ROUTES.SIGN_UP}>Create account</QuestionItemText>
        </QuestionContainer>
      </FieldsContainer>
    </FormStyled>
  );
}

export default FormLogin;
