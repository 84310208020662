import React, { useEffect } from 'react';
import { FormContainer } from './styles';
import FormSignUp from './signUpForm';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import { useSelector } from 'react-redux';

const SignUpPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(state => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(APP_ROUTES.MAIN);
    }
  }, [isLoggedIn]);

  return (
    <>
      {!isLoggedIn && (
        <FormContainer>
          <FormSignUp />
        </FormContainer>
      )}
    </>
  );
};

export default SignUpPage;
