import React, { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContentContainer, RootContainer } from './styles';
import { RegisterLayout } from '../registerLayout';
import { getUserInfo, logout } from '../../slices/auth';
import Footer from '../footer';
import { setMeta } from '../../slices/account';

export const BaseLayout = memo(props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector(state => state.auth);
  const onLogoutClickHandler = () => {
    dispatch(logout());
    dispatch(setMeta(null));
  };

  useEffect(() => {
    const status = searchParams.get('success_subscribe');
    if (isLoggedIn && String(status) === 'true') {
      setSearchParams();
      dispatch(getUserInfo());
    }
  }, [searchParams.get('success_subscribe')]);

  return (
    <RootContainer>
      {!isLoggedIn ? (
        <ContentContainer>{props.children}</ContentContainer>
      ) : (
        <RegisterLayout onLogoutClickHandler={onLogoutClickHandler} currentUser={user}>
          {props.children}
        </RegisterLayout>
      )}
      <Footer />
    </RootContainer>
  );
});
