import { GoogleMap, useJsApiLoader, Autocomplete, MarkerF, CircleF } from '@react-google-maps/api';
import React, { useState, useRef, useEffect } from 'react';
import { DiscreteSlider } from '../../../components/slider';
import { toast } from 'react-toastify';
import {
  MapsContainer,
  MainMapContainer,
  HeaderContainer,
  HeaderTitle,
  HeaderText,
  TextFieldStyled,
  TextFieldContainer,
  WatchButton,
  CurcularContainer,
} from './styles';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MainContainer } from '../firstLoginForm/styles';
import { setStep } from '../../../slices/profile';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/constants';
import { createMeta } from '../../../slices/account';

const MapComponent = () => {
  const [libraries] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_BASE_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector(state => state.properties);
  const { user } = useSelector(state => state.auth);

  const inputRef = useRef(null);
  const ref = useRef(null);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [place, setPlace] = useState(false);
  const [touched, setTouched] = useState(false);
  const [radius, setRadius] = useState(5);

  const mapContainerStyle = {
    width: '100%',
    height: '518px',
  };

  const center = {
    lat: 40.73061,
    lng: -73.935242,
  };

  const handleMapClick = event => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleValueTextChange = rediusInMeters => {
    setRadius(rediusInMeters);
  };

  const onPlaceChanged = () => {
    const place = inputRef.current.getPlace();
    if (!place || !place.geometry || !place.geometry.location) {
      setAddressError(true);
      setPlace(false);
      setMarker(null);
      return;
    }
    if (place || place.geometry.viewport || place.geometry.location) {
      setMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setPlace(true);
    }
  };

  const onPlaceChangedFromCard = () => {
    if (marker) {
      const place = {
        geometry: {
          location: new window.google.maps.LatLng(marker.lat, marker.lng),
        },
      };
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: place.geometry.location }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const fullAddress = results[0].formatted_address;
            // Вставьте fullAddress в поле автодополнения здесь
            setAddress(fullAddress);
            setAddressError(false);
            setPlace(true);
          } else {
            setPlace(false);
            setAddressError(true);
          }
        } else {
          toast.error('Error: ' + status);
        }
      });
    }
  };

  const helperTextSet = () => {
    if (touched && address === '') {
      return 'Address is required';
    }
    if (addressError) {
      return 'Address not found';
    }
  };

  const sendLocation = () => {
    const radiusInMeters = radius * 1609.344;
    const radiusLocation = {
      srid: 4326,
      latitude: marker.lat,
      longitude: marker.lng,
      distance: radiusInMeters,
    };
    dispatch(createMeta(radiusLocation));
    if (!user.is_subscribed_broker) {
      dispatch(setStep(3));
    } else {
      navigate(APP_ROUTES.MAIN);
    }
  };

  useEffect(() => {
    onPlaceChangedFromCard();
  }, [marker]);

  return (
    <MainContainer>
      <MainMapContainer>
        <HeaderContainer>
          <HeaderTitle>Select your target area</HeaderTitle>
          <HeaderText>We'll prioritize deals in the location you select.</HeaderText>
        </HeaderContainer>
        {isLoaded && (
          <MapsContainer>
            <TextFieldContainer>
              <Autocomplete
                style={{ width: '300px' }}
                onLoad={autocomplete => {
                  inputRef.current = autocomplete;
                }}
                onPlaceChanged={onPlaceChanged}>
                <TextFieldStyled
                  value={address}
                  fullWidth
                  error={(touched && address === '') || addressError}
                  helperText={helperTextSet()}
                  onChange={e => {
                    setAddressError(false);
                    setPlace(false);
                    setAddress(e.target.value);
                  }}
                  onBlur={() => setTouched(true)}
                  placeholder="Address or Location"
                  label="Address or Location"
                />
              </Autocomplete>
              <DiscreteSlider onValueTextChange={handleValueTextChange} />
              <WatchButton
                type="submit"
                variant="contained"
                disabled={!marker || !place || addressError || loading}
                onClick={sendLocation}>
                {loading ? <CircularProgress style={{ color: 'white' }} /> : <span>Watch Deals</span>}
              </WatchButton>
            </TextFieldContainer>
            <GoogleMap
              onClick={handleMapClick}
              mapContainerStyle={mapContainerStyle}
              center={marker ? marker : center}
              zoom={12}>
              <MarkerF position={marker} />
              <CircleF
                forwardRef={ref}
                center={marker}
                options={{
                  fillColor: 'rgba(198, 40, 40, 0.6)',
                  strokeColor: '#C62828',
                }}
                radius={Number(radius) * 1609.344}
              />
            </GoogleMap>
          </MapsContainer>
        )}
        {!isLoaded && (
          <CurcularContainer>
            <CircularProgress style={{ color: '#C12026' }} />
          </CurcularContainer>
        )}
      </MainMapContainer>
    </MainContainer>
  );
};

export default MapComponent;
