import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlockInfo,
  CustomTextField,
  LinkContainer,
  InformationContainer,
  MainContainer,
  TitleContainer,
  CustomCheckBox,
  CheckBoxLabel,
  CheckboxsContainer,
  SubTextDown,
  FieldsContainer,
  CardsContainer,
  ServiceDetailsBlock,
  ServiceDetailsFields,
  KeyboardBackSpaceIcon,
  CustomButton,
  PlusIcon,
  RedButton,
  AvatarImage,
  AccountButton,
  AvatarIcon,
  ImageInfo,
} from './styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import PaymentCard from '../../../components/payment-card-map';
import { createCompany, getAllProfileInfo } from '../../../slices/profile';
import { defaultValues } from './constants';
import Loader from '../../../components/loader';
import { choosePreferredPaymentMethod, createPaymentMethod, getPaymentMethods } from '../../../slices/payment';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { schemaFormFirstLogin, schemaUpdateProfile } from '../../../joi';
import { updateAccount } from '../../../slices/auth';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteAvatar, uploadAvatar } from '../../../slices/account';
import AccountService from '../../../services/account.service';
import { toast } from 'react-toastify';

const Profile = () => {
  const refInput = useRef(null);
  const [avatar, setAvatar] = useState(null);

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const { uploadImage } = useSelector(state => state.account);
  const { company, license, about, services, loading } = useSelector(state => state.profile);
  const { payments } = useSelector(state => state.payment);

  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schemaFormFirstLogin.concat(schemaUpdateProfile)),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    dispatch(getAllProfileInfo());
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    if (company) {
      const codesServices = services?.map(({ code }) => code) || [];
      const setServices = new Set(codesServices);
      const data = {
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        licenseState: license.state,
        licenseNumber: license.number,
        companyName: company.name,
        companyStreet: company.street_address,
        city: company.city,
        state: company.state,
        zipCode: company.zip_code,
        website: company.web_site,
        experience: about.experience,
        bio: about.bio,
        sold: about.sold,
        socialMedia: setServices.has('social_media_marketing'),
        mailers: setServices.has('mailers'),
        openHouses: setServices.has('open_houses'),
        listingPhoto: setServices.has('listing_photography'),
        listingVideo: setServices.has('listing_videography'),
        drone: setServices.has('drone_photography_video'),
        furniture: setServices.has('furniture_staging'),
      };
      reset(data);
    }
  }, [company, license, about, services, user]);

  useEffect(() => {
    if (!uploadImage) {
      AccountService.getAvatar().then(({ data }) => setAvatar(data));
    }
  }, [uploadImage]);

  const choosePaymentMethod = useCallback(id => {
    dispatch(choosePreferredPaymentMethod(id));
  }, []);

  const createPayment = () => {
    dispatch(createPaymentMethod());
  };

  const submit = data => {
    const updateAccountData = {};
    ['first_name', 'last_name', 'phone'].forEach(key => {
      if (data[key] !== user[key]) updateAccountData[key] = data[key];
    });
    if (Object.keys(updateAccountData).length) dispatch(updateAccount(updateAccountData));
    dispatch(createCompany(data));
  };

  const handleClick = () => {
    refInput.current.click();
  };

  const handleChange = event => {
    const _URL = window.URL || window.webkitURL;
    const file = event.target.files[0];
    const maxFileSize = 1 * 1024 * 1024; // 1mb
    if (file.size > maxFileSize) {
      toast.error('The uploaded image size is too large');
      return;
    }

    const img = new Image();
    const objectUrl = _URL.createObjectURL(file);

    img.src = objectUrl;
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      if (naturalWidth < 1000 && naturalHeight < 1000) {
        dispatch(uploadAvatar(file));
      } else {
        toast.error('The uploaded image is the wrong width or height greater than 1000 px');
      }
      _URL.revokeObjectURL(objectUrl);
    };
  };

  const deleteImage = () => {
    dispatch(deleteAvatar());
  };

  const readerImage = () => {
    return URL.createObjectURL(avatar);
  };

  if (loading || !company) return <Loader />;

  return (
    <MainContainer onSubmit={handleSubmit(submit)}>
      <LinkContainer to={'/'}>
        <KeyboardBackSpaceIcon />
        Profile
      </LinkContainer>
      <InformationContainer>
        <BlockInfo>
          <TitleContainer>Profile picture</TitleContainer>
          {avatar ? <AvatarImage src={readerImage()} alt={'image'} /> : <AvatarIcon></AvatarIcon>}
          <input
            type="file"
            onChange={handleChange}
            ref={refInput}
            style={{ display: 'none' }}
            accept="image/jpeg, image/png, image/jpg"
          />
          <AccountButton color={'error'} onClick={handleClick}>
            Upload new
          </AccountButton>
          <AccountButton color={'inherit'} onClick={deleteImage}>
            Remove <DeleteOutlineIcon />
          </AccountButton>
          <ImageInfo>
            You can upload a square image in jpg or png format, resolution from 120x120 to 1000x1000 pixels, size up to
            1MB
          </ImageInfo>
        </BlockInfo>
        <BlockInfo>
          <TitleContainer>Company</TitleContainer>
          <FieldsContainer>
            <CustomTextField
              name="companyName"
              label="Company name"
              placeholder="Company name"
              {...register('companyName')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.companyName?.message ? true : false}
              helperText={errors.companyName?.message}
            />
            <CustomTextField
              label="Company Street Address"
              name="companyStreet"
              placeholder="Company street"
              {...register('companyStreet')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.companyStreet?.message ? true : false}
              helperText={errors.companyStreet?.message}
            />
            <CustomTextField
              label="City"
              name="city"
              placeholder="City"
              {...register('city')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.city?.message ? true : false}
              helperText={errors.city?.message}
            />
            <CustomTextField
              label="State"
              name="state"
              placeholder="State"
              {...register('state')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.state?.message ? true : false}
              helperText={errors.state?.message}
            />
            <CustomTextField
              label="Zip Code"
              name="zipCode"
              placeholder="Zip Code"
              {...register('zipCode')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.zipCode?.message ? true : false}
              helperText={errors.zipCode?.message}
            />
            <CustomTextField
              label="Website"
              name="website"
              placeholder="https://"
              {...register('website')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.website?.message ? true : false}
              helperText={errors.website?.message}
            />
          </FieldsContainer>
        </BlockInfo>
        <BlockInfo>
          <TitleContainer>My information</TitleContainer>
          <FieldsContainer>
            <CustomTextField
              label="First name"
              placeholder="First name"
              {...register('first_name')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.first_name?.message ? true : false}
              helperText={errors.first_name?.message}
            />
            <CustomTextField
              label="Last name"
              name="lastName"
              placeholder="Last name"
              {...register('last_name')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.last_name?.message ? true : false}
              helperText={errors.last_name?.message}
            />
            <CustomTextField label="Email" name="email" disabled value={user?.email || '-'} />
            <CustomTextField
              label="Phone"
              name="phone"
              placeholder="Phone"
              {...register('phone')}
              inputProps={{ maxLength: 20 }}
              error={errors.phone?.message ? true : false}
              helperText={errors.phone?.message}
            />
          </FieldsContainer>
        </BlockInfo>
        <BlockInfo>
          <TitleContainer>License</TitleContainer>
          <FieldsContainer>
            <CustomTextField
              label="State"
              name="licenseState"
              placeholder="License state"
              {...register('licenseState')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.licenseState?.message ? true : false}
              helperText={errors.licenseState?.message}
            />
            <CustomTextField
              name="licenseNumber"
              label="License number"
              placeholder="License number"
              {...register('licenseNumber')}
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              error={errors.licenseNumber?.message ? true : false}
              helperText={errors.licenseNumber?.message}
            />
          </FieldsContainer>
        </BlockInfo>
      </InformationContainer>

      <div>
        <TitleContainer>Service Details</TitleContainer>
        <ServiceDetailsBlock>
          <CheckboxsContainer>
            <SubTextDown>(Services offered for applicable deals)</SubTextDown>
            <FormControlLabel
              control={<CustomCheckBox {...register('socialMedia')} checked={watch('socialMedia')} />}
              label={<CheckBoxLabel>Social Media Marketing</CheckBoxLabel>}
            />
            <FormControlLabel
              control={<CustomCheckBox {...register('mailers')} checked={watch('mailers')} />}
              label={<CheckBoxLabel>Mailers</CheckBoxLabel>}
            />
            <FormControlLabel
              control={<CustomCheckBox {...register('openHouses')} checked={watch('openHouses')} />}
              label={<CheckBoxLabel>Open Houses</CheckBoxLabel>}
            />
            <FormControlLabel
              control={<CustomCheckBox {...register('listingPhoto')} checked={watch('listingPhoto')} />}
              label={<CheckBoxLabel>Listing Photography</CheckBoxLabel>}
            />
            <FormControlLabel
              control={<CustomCheckBox {...register('listingVideo')} checked={watch('listingVideo')} />}
              label={<CheckBoxLabel>Listing Videography</CheckBoxLabel>}
            />
            <FormControlLabel
              control={<CustomCheckBox {...register('drone')} checked={watch('drone')} />}
              label={<CheckBoxLabel>Drone Photography/Video</CheckBoxLabel>}
            />
            <FormControlLabel
              control={<CustomCheckBox {...register('furniture')} checked={watch('furniture')} />}
              label={<CheckBoxLabel>Furniture Staging</CheckBoxLabel>}
            />
          </CheckboxsContainer>
          <ServiceDetailsFields>
            <CustomTextField
              name="experience"
              label="Years of realtor experience"
              placeholder="Years of realtor experience"
              {...register('experience')}
              type="number"
              min="0"
              InputProps={{ min: 0, max: 100 }}
              variant="outlined"
              error={errors.experience?.message ? true : false}
              helperText={errors.experience?.message}
            />
            <CustomTextField
              name="sold"
              label="How many homes have you sold in the past year?"
              placeholder="How many homes have you sold in the past year?"
              {...register('sold')}
              type="number"
              min="0"
              InputProps={{ min: 0 }}
              variant="outlined"
              error={errors.sold?.message ? true : false}
              helperText={errors.sold?.message}
            />
            <CustomTextField
              name="bio"
              label="Bio"
              placeholder="Bio"
              multiline
              fullWidth
              {...register('bio')}
              maxRows={10}
              minRows={5}
              inputProps={{ maxLength: 500 }}
              variant="outlined"
              error={errors.bio?.message ? true : false}
              helperText={errors.bio?.message}
            />
          </ServiceDetailsFields>
        </ServiceDetailsBlock>
      </div>

      <div>
        <TitleContainer>Payment Details</TitleContainer>
        <CardsContainer>
          {payments &&
            payments.map(payment => (
              <PaymentCard key={payment.id} card={payment} choosePayment={choosePaymentMethod} />
            ))}
          <CustomButton onClick={createPayment} variant="outlined">
            Add payment method <PlusIcon />
          </CustomButton>
          <RedButton type={'submit'} disabled={Object.keys(errors).length} fullWidth variant="contained" color="error">
            Save changes
          </RedButton>
        </CardsContainer>
      </div>
    </MainContainer>
  );
};

export default Profile;
