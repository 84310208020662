import { Button, Checkbox } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as Back } from '../../icons/back.svg';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 676px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 0 25px;
`;

export const CreatePropertyLabel = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${props => props.theme.typography.h1_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${props => props.theme.typography.h1.font};
  }
`;
export const PropertyInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 32px;
  }
`;

export const PropertyInfo = styled.h3`
  font: ${props => props.theme.typography.h3.font};
  margin-bottom: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 40px;
  }
`;

export const CheckBoxsContainer = styled.div`
  margin-top: 16px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100% !important;
`;

export const FormStyled = styled.form`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
`;

export const PropertyContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`;

export const CheckBoxStyled = styled(Checkbox)`
  &.Mui-checked {
    color: ${props => props.theme.palette.redMain} !important;
  }
`;

export const CheckBoxLabel = styled.span`
  color: ${props => props.theme.palette.simpleGray};
  font: ${props => props.theme.typography.txt.font};
  width: 100%;
`;

export const CreateButton = styled(Button)`
  width: 326px;
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    box-shadow: ${props => props.theme.shadow.buttonShadow};
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  ,
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const BackIcon = styled(Back)`
  width: 24px;
  height: 16px;
  margin-right: 12px;
`;

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomTextField = styled(TextField)`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 326px;
  }
`;
