import styled from 'styled-components';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../../icons/back.svg';

export const LogoIcon = styled(Logo)`
  width: 80%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 410px;
  }
`;

export const FormLabel = styled.h2`
  font: ${props => props.theme.typography.h2_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 36px;
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 272px;
  width: 100%;
  max-width: 476px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 32px;
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoginButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    height: 56px;
    background-color: ${props => props.theme.palette.redMain};
    border-radius: 12px;
    color: ${props => props.theme.palette.white};
    font: ${props => props.theme.typography.button.font};
  }
  &.MuiButton-root:hover {
    background-color: ${props => props.theme.palette.redMain};
  }
`;

export const LinkStyledBack = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.palette.black};
`;

export const BackIcon = styled(Back)`
  width: 24px;
  height: 16px;
  margin-right: 12px;
`;
